import { LayoutComponent } from './layout/layout.component'
import { DriverDataResolver, InitialDataResolver, OfficeDataResolver, WebsiteSettingsDataResolver } from './app.resolvers'
import { AuthGuard, DriverAuthGuard, OfficeAuthGuard } from './core/auth/guards/auth.guard'
import { Role } from './shared/enums/roles.enum'
import { NoAuthGuard } from './core/auth/guards/noAuth.guard'
import { Route } from '@angular/router'

export const guestsRoute: Route = {
    canMatch: [NoAuthGuard],
    component: LayoutComponent,
    data: {
        layout: 'empty'
    },
    children: [
        { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
        { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
        { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
        { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
        { path: 'rating', loadChildren: () => import('app/shared/components/job-checkout/job-rating/job-rating.module').then(m => m.JobRatingModule) },
        { path: 'public', loadChildren: () => import('app/modules/public/public.module').then(m => m.PublicModule) },
    ]
}

export const authenticatedRoute = {
    canMatch: [AuthGuard],
    component: LayoutComponent,
    data: {
        layout: 'empty'
    },
    children: [
        { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
        { path: 'confirm-membership',
            resolve: { websiteSettings: WebsiteSettingsDataResolver },
            loadChildren: () => import('app/modules/confirm-membership/confirm-membership.module').then(m => m.ConfirmMembershipModule) 
        },
        { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) },
        {
            path: 'reload',
            loadChildren: () => import('app/modules/reload/reload.module').then(m => m.ReloadModule)
        },
    ]
}

export const landingRoute = {
    component: LayoutComponent,
    resolve: {
        initialData: InitialDataResolver,
        websiteSettings: WebsiteSettingsDataResolver,
    },
    children: [
        { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
        { path: 'help', loadChildren: () => import('app/modules/help-center/help-center.module').then(m => m.HelpCenterModule) },
        { path: 'profile', loadChildren: () => import('app/modules/auth/profile/profile.module').then(m => m.ProfileModule) },
        { path: 'rating', loadChildren: () => import('app/shared/components/job-checkout/job-rating/job-rating.module').then(m => m.JobRatingModule) },
        { path: 'public', loadChildren: () => import('app/modules/public/public.module').then(m => m.PublicModule) },
        { path: 'chat', loadChildren: () => import('app/modules/customer/chat/customer-chat.module').then(m => m.CustomerChatModule) },
    ]
}

export const customerRoute = {
    canMatch: [AuthGuard],
    component: LayoutComponent,
    resolve: {
        initialData: InitialDataResolver,
        websiteSettings: WebsiteSettingsDataResolver,
    },
    children: [
        { path: '', loadChildren: () => import('app/modules/customer/dashboard/dashboard.module').then(m => m.CustomerDashboardModule) },
        { path: 'jobs', loadChildren: () => import('app/modules/customer/jobs/jobs.module').then(m => m.CustomerJobsModule) },
        {
            path: 'jobs', loadChildren: () => import('app/shared/modules/shared-jobs/shared-jobs.module').then(m => m.SharedJobsModule),
            data: {
                role: Role.Customer
            }
        },
        { path: 'sidemarks', loadChildren: () => import('app/modules/customer/storage/storage.module').then(m => m.CustomerStorageModule) },
        { path: 'billing', loadChildren: () => import('app/modules/customer/billing/billing.module').then(m => m.CustomerBillingModule) },
        { path: 'invite', loadChildren: () => import('app/modules/customer/invite/invite.module').then(m => m.CustomerInviteModule) },
        { path: 'book', loadChildren: () => import('app/modules/customer/book-job/customer-book-job.module').then(m => m.CustomerBookJobModule) },
        { path: 'book-warehouse', loadChildren: () => import('app/modules/customer/book-warehouse-job/customer-book-warehouse-job.module').then(m => m.CustomerBookWarehouseJobModule) },
        { path: 'quotes', loadChildren: () => import('app/modules/customer/quotes/quotes.module').then(m => m.CustomerQuotesModule) },
        { path: 'invoices', loadChildren: () => import('app/modules/customer/quotes/quotes.module').then(m => m.CustomerQuotesModule) },
        { path: 'example', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule) },
    ]
}

export const officeRoute = {
    canMatch: [OfficeAuthGuard],
    component: LayoutComponent,
    resolve: {
        initialData: OfficeDataResolver,
        websiteSettings: WebsiteSettingsDataResolver,
    },
    children: [
        { path: '', loadChildren: () => import('app/modules/office/office.module').then(m => m.OfficeModule) },
    ]
}

export const driverRoute = {
    canMatch: [DriverAuthGuard],
    component: LayoutComponent,
    resolve: {
        initialData: DriverDataResolver,
        websiteSettings: WebsiteSettingsDataResolver,
    },
    children: [
        { path: '', loadChildren: () => import('app/modules/driver/driver.module').then(m => m.DriverModule) },
    ]
}

export const notAuthorized = {
    canMatch: [AuthGuard],
    children: [
        { path: '', loadChildren: () => import('app/modules/error/error-403/error-403.module').then(m => m.Error403Module) },
    ]
}

export const undefinedRoutes = {
    canMatch: [AuthGuard],
    children: [
        { path: '', loadChildren: () => import('app/modules/error/error-404/error-404.module').then(m => m.Error404Module) },
    ]
}