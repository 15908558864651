import { MediaUpload } from "app/shared/db/db-domain.model";
import { DbService } from "app/shared/db/db.service";
import { db } from "../db.repository";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})

export class MediaUploadStorageService extends DbService<MediaUpload> {
    constructor() {
        super(db.mediaUploads);
    }

    async getFormDataById(id: string): Promise<FormData> {
        const mediaUpload = await this.get(id);
        if (mediaUpload) {
            const formData = new FormData();
            formData.append('file', mediaUpload.file);
            formData.append('mediaType', mediaUpload.mediaTypeId.toString());
            return formData;
        }
        return null;
    }
}

