import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { DriverShiftService } from 'app/shared/services/driver/driver-shift.service';
import { DriverShift } from 'app/shared/models/domain/domain.model';
import { AlertService } from 'app/shared/services/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserAccountView } from 'app/shared/models/views/user-account-view.model';
import { OfficeRoles, Role } from 'app/shared/enums/roles.enum';
import { OfficeTenantsService } from 'app/modules/office/tenants/office-tenants.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: false
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    userView: UserAccountView;
    isDriver: boolean = false;
    isAdmin: boolean = false;
    hasActiveShift: boolean = false;
    tenantSlug: string;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _authService: AuthService,
        private _driverShiftService: DriverShiftService,
        private _alertService: AlertService,
        private _userService: UserService,
        private officeTenantsService: OfficeTenantsService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.setupUserProfileDetails();

        this.tenantSlug = this.officeTenantsService.findSlugNameBaseOnRoute();

        if (this.isDriver) {
            this._driverShiftService.currentShift$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((shift: DriverShift) => {
                    this.hasActiveShift = shift?.bringgShiftId && !shift?.shiftEnd;
                    this._changeDetectorRef.markForCheck();
                });
            this._driverShiftService.getCurrentShift().subscribe();
        }

        this._userService.account$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.setupUserProfileDetails();
                this._changeDetectorRef.markForCheck();
            })
    }

    private setupUserProfileDetails() {
        this.userView = this._authService.userProfile;
        this.isDriver = this.userView?.userRoles?.some(x => x.roleName === Role.Driver);
        this.isAdmin = this.userView?.userRoles?.some(x => OfficeRoles.includes(x.roleName as Role));


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out
     */
    signOut(): void {
        this.officeTenantsService.tenantSlugNavigate('sign-out');
    }

    endShift(): void {
        this._driverShiftService.endShift().subscribe({
            next: () => {
                this._alertService.successToast('Shift ended successfully');
            },
            error: (error: HttpErrorResponse) => {
                this._alertService.errorToast('Error', error?.error?.ExceptionMessage);
            }
        });
    }

    startShift(): void {
        this._driverShiftService.startShift().subscribe({
            next: () => {
                this._alertService.successToast('Shift started successfully');
            },
            error: (error: HttpErrorResponse) => {
                this._alertService.errorToast('Error', error?.error?.ExceptionMessage);
            }
        });
    }
}
