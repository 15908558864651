import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import {
    Subject,
    filter,
    interval,
    map,
    mergeMap,
    takeUntil,
    tap,
    timer,
} from 'rxjs';
import { AuthService } from './core/auth/auth.service';
import { EventMediaUploadStorageService } from './shared/db/services/event-media-upload-storage.service';
import { OfficeRoles, Role } from './shared/enums/roles.enum';
import { ServiceWorkerService } from './shared/services/service-worker.service';
import { FirebaseMessagesService } from './shared/firebase-messages.service';
import { OfficeTenantsService } from './modules/office/tenants/office-tenants.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false,
})
export class AppComponent implements OnInit, OnDestroy {
    defaultTitle: string =
        'BringPro - Delivery, Storage and Installation Professionals';
    private unsubscribeAll = new Subject<void>();

    constructor(
        private titleService: Title,
        private activatedRoute: ActivatedRoute,
        private eventMediaUploadStorageService: EventMediaUploadStorageService,
        private authService: AuthService,
        private officeTenantsService: OfficeTenantsService,
        public serviceWorker: ServiceWorkerService,
        private firebaseMessagesService: FirebaseMessagesService,
        private router: Router,
        private el: ElementRef,
        private renderer: Renderer2
    ) {
        this.serviceWorker?.checkForUpdates();
        this.serviceWorker?.clearExistingBrowserCache();

        // Request for notification
        this.firebaseMessagesService.requestPermission();

        //repeat every 30 minutes
        interval(30 * 60 * 1000)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.authService.check().subscribe((isLogged) => {
                    if (!isLogged) return;
                    this.authService.refreshToken().subscribe(() => {});
                });
            });

        //repeat every 5 minutes
        timer(0, 5 * 60 * 1000)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                console.info('Checking for pending media uploads...');
                this.eventMediaUploadStorageService
                    .checkPendingUploadsAsync()
                    .then(() => {});
            });
    }
    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    ngOnInit(): void {
        this.router.events
            .pipe(
                takeUntil(this.unsubscribeAll),
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data),
                tap(({ title, description }: Data) => {
                    title = title ? `BringPro - ${title}` : this.defaultTitle;
                    this.titleService.setTitle(title);
                })
            )
            .subscribe();
        this.redirectAfterEmptyPath();
    }

    private redirectAfterEmptyPath(): void {
        const pathSegments = window.location.pathname
            .split('/')
            .filter(Boolean);
        const currentSlug = this.officeTenantsService
            .getAllTenantsViewItems()
            ?.find((item) => item.slug === pathSegments[0])?.slug;
        const roles = this.authService?.userProfile?.userRoles || [];
        const defaultRoute = roles.some((r) => r.roleName === Role.Driver)
            ? '/driver/dashboard'
            : roles.some((r) => OfficeRoles.includes(r.roleName as Role))
            ? '/office/reports'
            : '/dashboard';
        if (!pathSegments.length) {
            this.router.navigate([defaultRoute]);
        } else if (
            [`/${currentSlug}`, `/${currentSlug}/`].includes(
                window.location.pathname
            )
        ) {
            this.router.navigate([
                currentSlug ? `${currentSlug}${defaultRoute}` : defaultRoute,
            ]);
        }
    }
}
