import { EventMediaUpload, MediaUpload } from "app/shared/db/db-domain.model";
import { DbService } from "app/shared/db/db.service";
import { db } from "../db.repository";
import { MediaUploadStatus } from "../db.enum";
import { Injectable } from "@angular/core";
import { MediaService } from "app/shared/services/media.service";
import { MediaUploadStorageService } from "./media-upload-storage.service";
import { Observable, Subject, lastValueFrom } from "rxjs";
import { MediaTypeId } from "app/shared/enums/media-type.enum";
import { SubscriptionMediaView } from "app/shared/models/domain/domain-view.model";
import { emptyGuid } from "app/shared/constants/bringpro-constants";
import { Media } from "app/shared/components/page-layout/page-layout.types";
import { generateGuid } from "app/shared/functions/utils.type";


@Injectable({
    providedIn: 'root',
})
export class EventMediaUploadStorageService extends DbService<EventMediaUpload> {
    private lastUpload = new Subject<SubscriptionMediaView[]>();
    private eventUploads = new Subject<EventMediaUpload[]>();
    readonly maxRetries = 5;
    isUploading: boolean = false;
    lastUpload$: Observable<SubscriptionMediaView[]> = this.lastUpload.asObservable();
    observer$: Observable<EventMediaUpload[]> = this.eventUploads.asObservable();

    constructor(private mediaService: MediaService,
        private mediaUploadService: MediaUploadStorageService) {
        super(db.eventMediaUploads);
    }

    async checkPendingUploadsAsync(): Promise<void> {
        if (!this.isUploading) {
            this.uploadMediaInBackgroundAsync();
        }
    }

    async uploadMediaInBackgroundAsync(): Promise<void> {
        this.isUploading = true;
        const upload = await this.getNextAvailableUploadAsync();
        if (upload) {
            const mediaForm = await this.mediaUploadService.getFormDataById(upload.mediaUploadId);
            mediaForm.append('subscriptionEventId', upload.subscriptionEventId);
            mediaForm.append('storageItemInstanceId', upload.storageItemInstanceId);
            mediaForm.append('subscriptionId', upload.subscriptionId);
            mediaForm.append('projectId', upload.projectId);
            await this.update(upload);

            try {
                const response = await lastValueFrom(this.mediaService.upload(mediaForm));
                
                if (response && response?.medias?.length > 0) {
                    let uploads = response.medias.map(m => {
                        return this.getSubscriptionMediaView(upload, m);
                    })
                    this.lastUpload.next(uploads);
                    await this.mediaUploadService.delete(upload.mediaUploadId);
                    await this.delete(upload.id);
                }
                else {
                    throw new Error(`Failed to upload media. Response: ${JSON.stringify(response)}`);
                }
            }
            catch (error) {
                console.error(error);
                await this.increaseRetryCount(upload, error);
            }
            finally {
                this.uploadMediaInBackgroundAsync();
            }
        }
        else {
            this.isUploading = false;
        }
    }

    private getSubscriptionMediaView(upload: EventMediaUpload, m: Media): SubscriptionMediaView {
        return {
            mediaId: m.id,
            mediaType: m.mediaType,
            created: m.created,
            modified: m.modified,
            storageItemInstanceId: m.storageItemInstanceId,
            projectId: upload.projectId,
            subscriptionId: upload.subscriptionId,
            subscriptionEventId: upload.subscriptionEventId,
            description: m.description,
            url: m.url,
            fullUrl: m.fullUrl,
            fileType: m.fileType,
            title: m.title,
            userId: m.userId,
            id: m.subscriptionMediaId
        } as SubscriptionMediaView;
    }

    async getLocalSubscriptionMediaViewAsync(upload: EventMediaUpload, m: MediaUpload = null): Promise<SubscriptionMediaView> {
        m ??= await this.mediaUploadService.get(upload.mediaUploadId);
        const extension = m.fileName.split('.').pop().toLowerCase();
        const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(extension);

        return {
            mediaType: upload.mediaTypeId,
            storageItemInstanceId: upload.storageItemInstanceId,
            projectId: upload.projectId,
            subscriptionId: upload.subscriptionId,
            subscriptionEventId: upload.subscriptionEventId,
            fileType: isImage ? `image/${extension}` : 'application/octet-stream',
            title: m?.fileName,
            id: generateGuid(),
            url: `local.${extension}`,
            file: m?.file,
        } as any as SubscriptionMediaView;
    }

    async getNextAvailableUploadAsync(): Promise<EventMediaUpload | undefined> {
        let upload: EventMediaUpload = null;
        await db.transaction('rw', this.table, async () => {
            upload = await this.table
            .where('retries').belowOrEqual(this.maxRetries)
            .and(mediaUpload => mediaUpload.status !== MediaUploadStatus.Uploading)
            .first();
            if (!upload) return upload;

            upload.status = MediaUploadStatus.Uploading;
            await this.update(upload);
            return upload;
        });
        return upload;
    }

    async increaseRetryCount(mediaUpload: EventMediaUpload, error: any = null): Promise<number> {
        mediaUpload.retries++;
        mediaUpload.status = MediaUploadStatus.Error;
        mediaUpload.error = error?.message ??  JSON.stringify(error);
        return await this.update(mediaUpload);
    }

    override async add(item: EventMediaUpload): Promise<string> {
        const result = await super.add(item);
        this.checkPendingUploadsAsync();
        return result;
    }

    override async addMany(items: EventMediaUpload[]): Promise<string[]> {
        const result = await super.addMany(items);
        this.checkPendingUploadsAsync();
        return result;
    }

    async getMediaUploadsBySubscriptionEventId(subscriptionEventId: string, mediaTypes: MediaTypeId[] = null): Promise<EventMediaUpload[]> {
        mediaTypes ??= [MediaTypeId.WarehouseBillOfLading, MediaTypeId.WarehouseInstanceThumbnail]
        const result = await this.table.where('subscriptionEventId').equals(subscriptionEventId)
            //.and(m => mediaTypes.includes(m.mediaTypeId))
            .toArray();
        this.eventUploads.next(result);
        return result;
    }
}
