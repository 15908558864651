import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AlertService } from 'app/shared/services/alert.service';
import { OfficeTenantsService } from 'app/modules/office/tenants/office-tenants.service';
import { environment } from 'environments/environments';
import { errorHandler } from 'app/shared/functions/utils.type';
import { ErrorCodes } from 'app/shared/enums/error-code.enum';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private officeTenantsService: OfficeTenantsService,
        private alertService: AlertService
    ) {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();
        const websiteId = this.officeTenantsService.getTenantDetailsBySlug()?.tenantId || environment.defaultWebsiteId;
        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
            newReq = req.clone({
                headers: req.headers
                .set('Authorization', 'Bearer ' + this._authService.accessToken)
                .set('X-Custom-Website-Id', String(websiteId))
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {
                const interceptRequest = !newReq.headers.get('Intercept') || newReq.headers.get('Intercept') === 'true';
                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // Sign out
                    this._authService.signOut();

                    // Reload the app
                    if (!newReq?.url?.indexOf('/login')) {
                        location.reload();
                    }
                }
                else if (error instanceof HttpErrorResponse && (error.status === 500 || error.status === 400) && interceptRequest) {
                    if (error.error?.ExceptionMessage) {
                        error.error?.Exception === "Platform.Api.v3.Business.Framework.AppAuthException"
                            ? errorHandler(ErrorCodes.UserNotFound, this.alertService)
                            : this.alertService.errorToast('System Error', error.error?.ExceptionMessage, 2000);
                    }
                    else if (error.error?.title) {
                        this.alertService.errorToast('Validation Error', error.error?.title, 2000);
                    }
                }
                

                return throwError(() => error);
            })
        );
    }
}
