import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, of, switchMap, tap, throwError, timer } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environments';
import { PageResponse } from 'app/shared/models/response/page-response.model';
import { ConversationMessagesView, ConversationView } from 'app/shared/models/domain/domain-view.model';
import { PageRequest } from 'app/shared/models/request/page-request.model';
import { DraftMessages } from './chat.types';
import { CustomConversationView } from 'app/shared/models/domain/custom-views.model';
import { AlertService } from 'app/shared/services/alert.service';
import { Params } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class OfficeChatService
{
    private baseUrl: string = environment.apiUrl;
    private _chat: BehaviorSubject<ConversationMessagesView[]> = new BehaviorSubject(null);
    private _chats: BehaviorSubject<ConversationView[]> = new BehaviorSubject(null);
    private _conversationView: BehaviorSubject<ConversationView> = new BehaviorSubject(null);
    public draftMessage: BehaviorSubject<DraftMessages> = new BehaviorSubject(null);
    public reconnectConnectionStatus: BehaviorSubject<Boolean> = new BehaviorSubject(false);
    private hubConnection: signalR.HubConnection;
    /**
     * Constructor
    */
   constructor(
    private _httpClient: HttpClient,
    private alertService: AlertService,
    private authService: AuthService)
   {
        const options: IHttpConnectionOptions = {
            accessTokenFactory: () => {
                return this.authService?.accessToken;
            }
        };
        this.hubConnection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Information)
        .withUrl(`${environment.apiUrl}hub/chat`, options)
        .build();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for chat
     */
    get chat$(): Observable<ConversationMessagesView[]>
    {
        return this._chat.asObservable();
    }

    /**
     * Getter for chats
     */
    get chats$(): Observable<ConversationView[]>
    {
        return this._chats.asObservable();
    }

    /**
     * Getter for conversation
     */
    get conversationView$(): Observable<ConversationView>
    {
        return this._conversationView.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get conversation
     */
    getConversations(params: Params): Observable<PageResponse<ConversationView>>
    {
        let request: PageRequest = new PageRequest();
        request.itemsPerPage = environment.defaultItemsPerPage
        request.sort = 'lastMessageDate'
        request.sortDir = 'desc'
        if (params?.websiteId) {
            request.find['websiteId'] = params?.websiteId;
        }
        request.find['deleted'] = 'IS NULL';
        return this._httpClient.get<PageResponse<ConversationView>>(`${this.baseUrl}api/admin/view/conversation`, {params: request.toParams()}).pipe(
            tap((response: PageResponse<ConversationView>) => {
                this._chats.next(response?.items);
            })
        );
    }

    /**
     * Get chat
     *
     * @param id
     */
    getChatById(request): Observable<PageResponse<ConversationMessagesView>>
    {
        return this._httpClient.get<PageResponse<ConversationMessagesView>>(`${this.baseUrl}api/admin/view/conversation/messages`, {params: request}).pipe(
            map((chat) => {

                // Update the chat
                if (!request['find[created:lt]'] && !request['find[mediaId]']) {
                    this._chat.next(chat?.items);
                }
                // Return the chat
                return chat;
            }),
            switchMap((chat) => {

                if ( !chat )
                {
                    return throwError('Could not found chat with id of '+ request['find[conversationId]'] + '!');
                }

                return of(chat);
            })
        );
    }

    /**
     * Reset the selected chat
     */
    resetChat(): void
    {
        this._chat.next(null);
        this._conversationView.next(null);
    }

    /**
     * Create Conversation
     */
    createConversation(userId: string, websiteId: number, isOffice = true): Observable<PageResponse<any>> {
        return this._httpClient.post<PageResponse<any>>(`${this.baseUrl}api/admin/conversation/create`, { websiteId, userId, isOffice }).pipe(
            tap((response) => {
                return response;
            })
        );
    }
  
    /**
     * Delete Conversation
     */
    deleteConversation(conversationId: string): Observable<PageResponse<CustomConversationView>> {
        return this._httpClient.delete<PageResponse<CustomConversationView>>(`${this.baseUrl}api/admin/conversation/${conversationId}`);
    }

    /**
     * Get Single Conversation
     */
    getConversationById(id: string, websiteId: number): Observable<PageResponse<CustomConversationView>> {
        let request = new PageRequest();
        if (websiteId) request.find['websiteId'] = websiteId;
        return this._httpClient.get<PageResponse<CustomConversationView>>(`${this.baseUrl}api/admin/view/conversation/${id}`, {params: request.toParams()}).pipe(
            tap((response) => {
                this._conversationView.next(response?.item)
                return response;
            })
        );
    }

    /**
     * Create group
     */
    createGroup(userIds: string[], websiteId: number, name: string, mediaId: number,isOffice = true): Observable<PageResponse<any>> {
        return this._httpClient.post<PageResponse<any>>(`${this.baseUrl}api/admin/conversation/create-group`, { websiteId, userIds, name, mediaId, isOffice }).pipe(
            tap((response) => {
                return response;
            })
        );
    }

    /**
     * Update contact info
     */
    updateContactInfo(id: string, name: string, mediaId: number) {
        return this._httpClient.patch<any>(`${this.baseUrl}api/admin/conversation/${id}`, { id, name, mediaId }).pipe(
            tap((response) => {
                return response;
            })
        );
    }
    
    /**
     * Remove group member
     */
    removeGroupMember(id: string, userIds: string[]) {
        return this._httpClient.post<any>(`${this.baseUrl}api/admin/conversation/${id}/users/remove`, { id, userIds }).pipe(
            tap((response) => {
                return response;
            })
        );
    }
    
    /**
     * Add new group member
     */
    addNewGroupMember(id: string, userIds: string[]) {
        return this._httpClient.post<any>(`${this.baseUrl}api/admin/conversation/${id}/users`, { id, userIds }).pipe(
            tap((response) => {
                return response;
            })
        );
    }

    /**
     * SignalR connection
     */
    startConnection(): Observable<void> {
        return new Observable<void>((observer) => {
            if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
                observer.next();
                observer.complete();
                return;
            }
            this.hubConnection.start()
            .then(() => {
                observer.next();
                observer.complete();
                this.hubConnection.onclose(() => {
                    this.reconnectConnection();
                });
            })
            .catch((error) => {
                observer.error(error);
            });
        });
    }

    /**
     * Reconnect SignalR connection
     */
    private reconnectConnection() {
        timer(10 * 1000).pipe(
            switchMap(() => this.startConnection())
        ).subscribe(() => {
            this.reconnectConnectionStatus.next(true);
        });
    }

    /**
     * Receive Message
     */
    receiveMessage(): Observable<ConversationMessagesView> {
        return new Observable<ConversationMessagesView>((observer) => {
            this.hubConnection.on('ReceiveMessage', (message: ConversationMessagesView) => {
                observer.next(message);
            });
        });
    }
    
    /**
     * Send Message
     */
    sendMessage(userId: string, message: string, mediaId: number): void {
        (this.hubConnection.state === signalR.HubConnectionState.Connected) ?
            this.hubConnection.invoke('SendMessage', userId, message, mediaId) : this.handleConnectionError();
    }
    
    /**
     * Handle connection error 
     */
    handleConnectionError(): void {
        this.startConnection().subscribe();
        this.alertService.errorToast("Disconnected from chat service.  Check internet connection")
    }

    /**
     * Mark as read message
     */
    markAsRead(conversationId: string) {
        this.hubConnection.invoke("MarkAsRead", conversationId);
    }

    /**
     * Receive message read
     */
    receiveMarkAsRead(): Observable<ConversationMessagesView> {
        return new Observable<ConversationMessagesView>((observer) => {
            this.hubConnection.on('MessageRead', (message: ConversationMessagesView) => {
                observer.next(message);
            });
        });
    }

    /**
     * Send Edit message
     */
    requestEditMessage(messageId: string, newMessage: string): void {
        (this.hubConnection.state === signalR.HubConnectionState.Connected) ?
            this.hubConnection.invoke("EditMessage", messageId, newMessage) : this.handleConnectionError();
    }

    /**
     * Receive Edit Message
     */
    responseEditMessage(): Observable<ConversationMessagesView> {
        return new Observable<ConversationMessagesView>((observer) => {
            this.hubConnection.on('EditedMessage', (message: ConversationMessagesView) => {                
                observer.next(message);
            });
        });
    }

    /**
     * Request delete message
     */
    requestDeleteMessage(messageId: string) {
        (this.hubConnection.state === signalR.HubConnectionState.Connected) ?
            this.hubConnection.invoke("DeleteMessage", messageId) : this.handleConnectionError();
    }
  
    /**
     * Response delete message
     */
    responseDeleteMessage(): Observable<ConversationMessagesView> {
        return new Observable<ConversationMessagesView>((observer) => {
            this.hubConnection.on('DeletedMessage', (message: ConversationMessagesView) => {                
                observer.next(message);
            });
        });
    }
}
