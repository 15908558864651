import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReadOnlyCoreApiService } from '../common/read-only-core-api.service.';
import { PageResponse } from 'app/shared/models/response/page-response.model';
import { DriverShift } from 'app/shared/models/domain/domain.model';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({providedIn: 'root'})
export class DriverShiftService extends ReadOnlyCoreApiService<DriverShiftService> {
    protected endpoint = 'api/driver/shift';
    private currentShift: BehaviorSubject<DriverShift> = new BehaviorSubject<DriverShift>(null);

    get currentShift$() {
        return this.currentShift.asObservable();
    }
    constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    getCurrentShift() : Observable<DriverShift> {
        return this.httpClient.get<PageResponse<DriverShift>>(`${this.baseUrl}${this.endpoint}/current`)
            .pipe(map(response => {
                if (response.item != null) {
                    this.currentShift.next(response.item);
                }
                return response.item
            }));
    }
    
    startShift() : Observable<DriverShift> {
        return this.httpClient.post<PageResponse<DriverShift>>(`${this.baseUrl}${this.endpoint}/start`, null)
            .pipe(map(response => {
                if (response.item != null) {
                    this.currentShift.next(response.item);
                }
                return response.item
            }));
    }

    endShift() {
        return this.httpClient.post<PageResponse<DriverShift>>(`${this.baseUrl}${this.endpoint}/end`, null)
            .pipe(map(response => {
                if (response.item != null) {
                    this.currentShift.next(response.item);
                }
                return response.item
            }));
    }
}