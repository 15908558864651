import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError, timer } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environments';
import { ConversationMessagesView, ConversationView } from 'app/shared/models/domain/domain-view.model';
import { PageResponse } from 'app/shared/models/response/page-response.model';
import { CustomConversationView } from 'app/shared/models/domain/custom-views.model';
import { DraftMessages } from 'app/modules/office/chat/chat.types';
import { AlertService } from 'app/shared/services/alert.service';
import { PageRequest } from 'app/shared/models/request/page-request.model';
import { OfficeTenantsService } from 'app/modules/office/tenants/office-tenants.service';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomerChatService {
  private baseUrl: string = environment.apiUrl;
  private _chat: BehaviorSubject<ConversationMessagesView[]> = new BehaviorSubject(null);
  private _chats: BehaviorSubject<CustomConversationView[]> = new BehaviorSubject(null);
  private _conversationView: BehaviorSubject<ConversationView> = new BehaviorSubject(null);
  public draftMessage: BehaviorSubject<DraftMessages> = new BehaviorSubject(null);
  public reconnectConnectionStatus: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  private hubConnection: signalR.HubConnection;
  
  /**
   * Constructor
  */
constructor(
    private _httpClient: HttpClient, 
    private authService: AuthService, 
    private alertService: AlertService, 
    private officeTenantsService: OfficeTenantsService
) {
    const options: IHttpConnectionOptions = {
        accessTokenFactory: () => {
            return this.authService?.accessToken;
        }
    };
    this.hubConnection = new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Information)
    .withUrl(`${environment.apiUrl}hub/chat`, options)
    .build();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for chat
   */
  get chat$(): Observable<ConversationMessagesView[]>
  {
      return this._chat.asObservable();
  }

  /**
   * Getter for chats
   */
  get chats$(): Observable<ConversationView[]>
  {
      return this._chats.asObservable();
  }

  /**
   * Getter for conversation
   */
  get conversationView$(): Observable<ConversationView>
  {
      return this._conversationView.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get chats
   */
  getConversations(params: Params): Observable<PageResponse<CustomConversationView>>
  {
    let request: PageRequest = new PageRequest();
    request.sort = 'lastMessageDate'
    request.sortDir = 'desc'
    if (params?.websiteId) {
        request.find['websiteId'] = params?.websiteId;
    }
    request.find['deleted'] = 'IS NULL'
    return this._httpClient.get<PageResponse<ConversationView>>(`${this.baseUrl}api/customer/view/conversation`, {params: request.toParams()}).pipe(
        tap((response: PageResponse<CustomConversationView>) => {
        this._chats.next(response?.items);
        })
    );
  }

  /**
   * Create conversation
   */
  createConversation(): Observable<PageResponse<ConversationView>>
  {
    return this._httpClient.get<PageResponse<ConversationView>>(`${this.baseUrl}api/customer/view/conversation/office`);
  }
  
  /**
   * Get conversation by id
   */
  getConversationById(conversationId: string, websiteId: number): Observable<PageResponse<ConversationView>>
  {
    let request = new PageRequest();
    if (websiteId) request.find['websiteId'] = websiteId;
    return this._httpClient.get<PageResponse<ConversationView>>(`${this.baseUrl}api/customer/view/conversation/${conversationId}`, {params : request.toParams()}).pipe(
        tap((response: PageResponse<ConversationView>) => {
            this._conversationView.next(response?.item);
        })
    );
  }


  /**
   * Get chat
   *
   * @param id
   */
  getChatById(request): Observable<PageResponse<ConversationMessagesView>>
  {
    return this._httpClient.get<PageResponse<ConversationMessagesView>>(`${this.baseUrl}api/customer/view/conversation/messages`, {params: request}).pipe(
        map((chat) => {
            // Update the chat
            if (!request['find[created:lt]']) {
                this._chat.next(chat?.items);
            }
            // Return the chat
            return chat;
        }),
        switchMap((chat) => {

            if ( !chat )
            {
                return throwError('Could not found chat with id of '+ request['find[conversationId]'] + '!');
            }

            return of(chat);
        })
    );
  }

    /**
     * Reset the selected chat
     */
    resetChat(): void
    {
        this._chat.next(null);
        this._conversationView.next(null);
    }

    /**
     * SignalR connection
     */
    startConnection(): Observable<void> {
        return new Observable<void>((observer) => {
            if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
                observer.next();
                observer.complete();
                return;
            }
            this.hubConnection.start()
            .then(() => {
                observer.next();
                observer.complete();
                this.hubConnection.onclose(() => this.reconnectConnection());
            })
            .catch((error) => {
                observer.error(error);
            });
        });
    }
    
    /**
     * Reconnect SignalR connection
     */
    private reconnectConnection() {
        timer(10 * 1000).pipe(
            switchMap(() => this.startConnection())
        ).subscribe(() => {
            this.reconnectConnectionStatus.next(true);
        });
    }
  
    /**
     * Receive Message
     */
    receiveMessage(): Observable<ConversationMessagesView> {
        return new Observable<ConversationMessagesView>((observer) => {
            this.hubConnection.on('ReceiveMessage', (message: ConversationMessagesView) => {
                observer.next(message);
            });
        });
    }
  
    /**
     * Send Message
     */
    sendMessage(userId: string, message: string, mediaId = null): void {
        (this.hubConnection.state === signalR.HubConnectionState.Connected) ?
        this.hubConnection.invoke('SendMessageToOffice',userId, message, mediaId) : this.handleConnectionError();
    }

    /**
     * Handle connection error 
     */
    handleConnectionError(): void {
        this.startConnection().subscribe();
        this.alertService.errorToast("Disconnected from chat service.  Check internet connection")
    }

    /**
     * Mark as read message
     */
    markAsRead(conversationId: string) {
        this.hubConnection.invoke("MarkAsRead", conversationId);
    }

    /**
     * Receive message read
     */
    receiveMarkAsRead(): Observable<ConversationMessagesView> {
        return new Observable<ConversationMessagesView>((observer) => {
            this.hubConnection.on('MessageRead', (message: ConversationMessagesView) => {
                observer.next(message);
            });
        });
    }

    /**
     * Receive Edited Message
     */
    responseEditMessage(): Observable<ConversationMessagesView> {
        return new Observable<ConversationMessagesView>((observer) => {
            this.hubConnection.on('EditedMessage', (message: ConversationMessagesView) => {                
                observer.next(message);
            });
        });
    }
    
    /**
     * Response delete message
     */
    responseDeleteMessage(): Observable<ConversationMessagesView> {
        return new Observable<ConversationMessagesView>((observer) => {
            this.hubConnection.on('DeletedMessage', (message: ConversationMessagesView) => {                
                observer.next(message);
            });
        });
    }
}
