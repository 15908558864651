export enum Role {
    Banned = "Banned",
    Driver = "Driver",
    Administrator = "Administrator",
    CustomerService = "CustomerService",
    Sales = "Sales",
    Customer = "Customer",
    Public = "Public",
    Warehouse = "Warehouse",
    Tenant = "Tenant"
}

export const NotAuthorizedModuleBasedOnRole = {
    [Role.Warehouse]: ['/office/quotes','/office/invoices','/office/users','/office/developer', '/office/chat'],
    [Role.CustomerService]: ['/office/tenants', '/office/developer'],
    [Role.Tenant]: ['/office/developer']
};

export const AuthorizedRouteBasedOnRole = {
    [Role.Warehouse]: ['/warehouse', '/office/tenants', '/locations']
}

export const HideNavigationBasedOnRole = {
    [Role.Warehouse]: ['quotes','invoices','users','developer','chat'],
    [Role.CustomerService]: ['tenants', 'developer'],
    [Role.Tenant]: ['developer']
};

export const OfficeRoles = [Role.Administrator, Role.Tenant, Role.Warehouse, Role.CustomerService];
