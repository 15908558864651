import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camelCaseToWords',
    standalone: false
})

export class CamelCaseToWordsPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return value?.replace(/([A-Z])/g, ' $1')
            // uppercase the first character
            ?.replace(/^./, function (str) {
                return str?.toUpperCase();
            })
            ?.replace('I D', 'Id')
    }

}
