import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NotificationToast } from 'app/shared/models/domain/custom-views.model';

@Injectable({
    providedIn: 'root',
})

export class AlertService {

    constructor(private router: Router) { }

    defaultCustomClass = {
        confirmButton: 'bg-gradient-to-r from-orange-600 dark-shadow to-orange-400 border-solid border-2 border-white text-white w-24 font-bold py-2 px-4 rounded-full focus:ring-0',
        cancelButton: 'bg-white dark-shadow text-orange-500 border border-solid border-orange-500 w-24 font-bold py-2 px-4 rounded-full focus:ring-0',
        closeButton: 'bg-white dark-shadow text-orange-500 border border-solid border-orange-500 w-24 font-bold py-2 px-4 rounded-full focus:ring-0',
        denyButton: 'bg-white dark-shadow text-orange-500 border border-solid border-orange-500 w-24 font-bold py-2 px-4 rounded-full focus:ring-0',
    };

    errorToast(title: string, text = null, timer: number = null): any {
        Swal.fire({
            toast: true,
            position: 'bottom-start',
            showConfirmButton: false,
            timer: timer || 5000,
            timerProgressBar: false,
            customClass: { ...this.defaultCustomClass, title: 'text-red-500', 'container': 'toast-mobile' },
            title: title,
            text: text || 'Error',
            icon: 'error'
        });
    }

    successToast(title: string, text = null, timer: number = null): any {
        Swal.fire({
            toast: true,
            position: 'bottom-start',
            showConfirmButton: false,
            timer: timer || 1000,
            timerProgressBar: true,
            customClass: { ...this.defaultCustomClass, title: 'text-accent', 'container': 'toast-mobile' },
            title: title,
            text: text || 'Success',
            icon: 'success'
        });
    }

    notificationToast(notification: NotificationToast): void {
        const { groupName, userName, avatar, message, messageMediaFullUrl, conversationId, isAdmin, websiteId, chatService, timer } = notification;
        const shortMessage = message?.length > 200 ? message.slice(0, 200) + '...' : message;

        const avatarHtml = avatar
            ? `<img src="${avatar}" alt="${userName}" class="w-10 h-10 rounded mr-4">`
            : `<div class="flex items-center justify-center w-10 h-10 mr-4 rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">${userName?.charAt(0)}</div>`;

        const messageMediaUrl = messageMediaFullUrl
            ? `<div class="flex items-center gap-2"><span class="material-icons icon-size-5 text-gray-600">photo_camera</span><p class="text-base">${message || 'Media'}</p></div>`
            : `<p class="text-base">${shortMessage}</p>`;

        const html = `
            <div class="flex items-start justify-between">
                <div class="flex items-center">
                     ${avatarHtml}
                    <div>
                        <strong class="text-bring-blue text-lg line-clamp-1">${groupName ? `${groupName} - ${userName}` : userName}</strong>
                        ${messageMediaUrl}
                    </div>
                </div>
                <button class="close-toast-btn cursor-pointer"><span class="material-icons icon-size-5 text-gray-600">close</span></button>
            </div>`;

        Swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: timer || 5000,
            timerProgressBar: true,
            customClass: { ...this.defaultCustomClass, title: 'text-bring-blue', 'container': 'toast-mobile' },
            html,
            didOpen: () => {
                // Close Toast
                document.querySelector('.close-toast-btn')?.addEventListener('click', () => Swal.close());

                // Handle redirect on clicking the toast
                if (conversationId) {
                    const toastContainer = document.querySelector('.swal2-html-container');
                    if (toastContainer) {
                        toastContainer.classList.add('cursor-pointer');
                        toastContainer.addEventListener('click', () => {
                            chatService.markAsRead(conversationId)
                            chatService.getConversations({websiteId}).subscribe();
                            this.router.navigate([isAdmin ? `/office/chat/${conversationId}` : `chat/${conversationId}`]);
                            Swal.close();
                        });
                    }
                }

                const htmlContainer = document.querySelector('.swal2-html-container');
                if (htmlContainer) htmlContainer.classList.remove('swal2-html-container');
            }
        });
    }

    warnToast(title: string, text = null, timer: number = null): any {
        Swal.fire({
            toast: true,
            position: 'bottom-start',
            showConfirmButton: false,
            timer: timer || 5000,
            timerProgressBar: true,
            customClass: { ...this.defaultCustomClass, title: 'text-accent', 'container': 'toast-mobile' },
            title: title,
            text: text || 'Warning',
            icon: 'warning'
        });
    }

    success(text = null, title: string = 'Success'): any {
        return Swal.fire({
            title: title,
            text: text || 'Success',
            icon: 'success',
            confirmButtonText: 'Close',
            customClass: this.defaultCustomClass,
        });
    }

    warn(title: string, text = null): any {
        return Swal.fire({
            title: title,
            text: text || 'Warning',
            icon: 'warning',
            confirmButtonText: 'Continue',
            customClass: this.defaultCustomClass,
        });
    }

    error(title: string, text = null): any {
        return Swal.fire({
            title: title,
            html: text || 'Error',
            icon: 'error',
            confirmButtonText: 'Continue',
            customClass: this.defaultCustomClass,
        });
    }

    confirm(title: string, text = null, confirmText = null, cancelText = null, icon?: string): Promise<{isConfirmed:boolean}> {
        return Swal.fire({
            title: title,
            html: text || 'Warning',
            icon: icon || 'warning',
            showCancelButton: true,
            confirmButtonText: confirmText || 'Continue',
            cancelButtonText: cancelText || 'Cancel',
            customClass: this.defaultCustomClass,
            inputAutoFocus: false,
        });
    }

    confirmWithTextArea(title: string, text = null, confirmText = null, cancelText = null, inputLabel: any = '', inputType = 'text', inputValue = ''): Promise<{isConfirmed:boolean}> {
        return Swal.fire({
            title: title,
            input: inputType,
            inputLabel: inputLabel,
            inputAttributes: {
                autocapitalize: 'off',
                autocomplete: 'off',
                value: inputValue,
                required: true
            },
            inputValidator: (value) => {
                if (!value) {
                    return 'This field is required.';
                }
            },
            inputValue: inputValue,
            html: text || 'Warning',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: confirmText || 'Continue',
            cancelButtonText: cancelText || 'Cancel',
            autocomplete: 'off',
            customClass: this.defaultCustomClass,
            preConfirm: () => {
                return document.querySelector('input').value;
            },
            didOpen: () => {
                const input = Swal.getInput();
                const confirmButton = Swal.getConfirmButton();    
                confirmButton.disabled = true;
                input.addEventListener('input', () => {
                    if (input.value.toLowerCase() === 'delete') {
                        confirmButton.disabled = false;
                    } else {
                        confirmButton.disabled = true;
                    }
                });
            }
        });
    }

    confirmWithThreeButton(title: string, text = null, confirmText = null, denyText = null, cancelText = null): Promise<{isConfirmed:boolean, isDenied:boolean}> {
        return Swal.fire({
            title: title,
            html: text || 'Warning',
            icon: 'warning',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: confirmText || 'Continue',
            denyButtonText: denyText || 'Deny',
            customClass: this.defaultCustomClass,
        });
    }

}
