import { HttpClient } from "@angular/common/http";
import { PageResponse } from "app/shared/models/response/page-response.model";
import { Observable, map } from "rxjs";
import { ReadOnlyCoreApiService } from "./read-only-core-api.service.";
import { Injectable } from "@angular/core";

@Injectable()
export class CoreApiService<T> extends ReadOnlyCoreApiService<T> {
    constructor(protected httpService: HttpClient, endpoint: string = null) {
        super(httpService, endpoint);
    }
    
    create(item: T): Observable<T> {
        return this.httpService.post<PageResponse<T>>(`${this.baseUrl}api/${this.endpoint}`, item)
            .pipe(map((res) => res.item));
    }

    update(id: number | string, item: T): Observable<T> {
        return this.httpService.put<PageResponse<T>>(`${this.baseUrl}api/${this.endpoint}/${id}`, item)
            .pipe(map((res) => res.item));
    }

    patch(id: number | string, item: Partial<T>): Observable<T> {
        return this.httpService.patch<PageResponse<T>>(`${this.baseUrl}api/${this.endpoint}/${id}`, item)
            .pipe(map((res) => res.item));
    }

    delete(id: number | string): Observable<T> {
        return this.httpService.delete<PageResponse<T>>(`${this.baseUrl}api/${this.endpoint}/${id}`)
            .pipe(map((res) => res.item));
    }
}

