import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environments";
import { Observable, catchError, map, throwError, Subject } from "rxjs";
import { Media, ParentDrawerUploadRequest, ParentDrawerUploadResponse } from "../components/page-layout/page-layout.types";
import { Medium } from "../models/domain/domain.model";
import { MediaTypeId } from "../enums/media-type.enum";
import { PageResponse } from "../models/response/page-response.model";
import { FuseLoadingService } from "@fuse/services/loading";

@Injectable({
    providedIn: 'root',
})

export class MediaService {
    baseUrl = environment.apiUrl;
    httpOptions: any = {
        withCredentials: true,
        observe: 'response' as 'response'
    };

    mediasUploaded$: Subject<ParentDrawerUploadResponse> = new Subject<ParentDrawerUploadResponse>();

    constructor(private http: HttpClient, private _fuseLoadingService: FuseLoadingService) {
    }

    getMedias(request): Observable<Medium[]> {
        let params = request;
        const httpOptionsLocal = { params: params };
        return this.http.get<any>(`${this.baseUrl}api/media`, httpOptionsLocal)
            .pipe(
                map((response: PageResponse<Medium>) => {
                    return response?.items;
                })
            );
    }

    uploadSignature(image: string, mediaTypeId: MediaTypeId = null, request: ParentDrawerUploadRequest = null, name: string = null, uploadMedia: boolean = false): Observable<any> {
        const options: object = {
            observe: 'response' as 'response',
            headers: {
                // meta: [new InterceptorMetaOptions(false, true, false)]
            }
        };

        const block = image.split(';');
        const contentType = block[0].split(':')[1];
        const base64 = block[1].split(',')[1];
        const byteString = atob(base64);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: contentType });
        name = name || new Date().getTime().toString();
        let imageName = `signature_${name}.png`;
        if (uploadMedia) imageName = name;
        const file = new File([blob], imageName, { type: contentType });

        const formData = new FormData();
        formData.append('image', file, imageName);
        if (uploadMedia) {
            formData.append('mediaType', (mediaTypeId ?? MediaTypeId.ChatMessage).toString());
        } else {
            formData.append('mediaType', (mediaTypeId ?? MediaTypeId.BookingSignature).toString());
        }
        return this.http.post(this.baseUrl + 'api/media/upload', formData, options)
            .pipe(
                map((res: HttpResponse<any>) => {
                    if (res) {

                        const ret: ParentDrawerUploadResponse = {
                            medias: res.body.items as Media[]
                            , request: request
                        };

                        this.mediasUploaded$.next(ret);

                        return ret;
                    }
                    return null;
                }), catchError((err = null) => {
                    return throwError(() => err);
                }));
    }

    uploadToApi(files: FormData, apiUrl: string): Observable<any> {
        const options: object = {
            observe: 'response' as 'response',
            headers: {
                // meta: [new InterceptorMetaOptions(false, true, false)]
            }
        };

        return this.http.post(`${this.baseUrl}${apiUrl}`, files, options)
            .pipe(
                map((res: HttpResponse<any>) => {
                    if (res) {
                        return res.body.item || res.body.items;
                    }
                    return null;
                }), catchError((err = null) => {
                    return throwError(() => err);
                }));
    }

    upload(files: FormData, request: ParentDrawerUploadRequest = null): Observable<ParentDrawerUploadResponse> {
        const options: object = {
            observe: 'response' as 'response',
            headers: {
                hideLoading: 'true'
            }
        };

        return this.http.post(this.baseUrl + 'api/media/upload', files, options)
            .pipe(
                map((res: HttpResponse<PageResponse<Media>>) => {
                    if (res) {
                        const ret: ParentDrawerUploadResponse = {
                            medias: res.body.items
                            , request: request
                        };

                        this.mediasUploaded$.next(ret);
                        return ret;
                    }
                    return null;
                }), catchError((err) => {
                    this._fuseLoadingService._setLoadingStatus(false, this.baseUrl + 'api/media/upload');
                    return throwError(() => err);
                }));
    }
}
