import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { DatePipe } from '@angular/common';
import { provideEnvironmentNgxMask, NgxMaskConfig } from 'ngx-mask';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { environment } from 'environments/environments';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { OfficeTenantsService } from './modules/office/tenants/office-tenants.service';
import { TenantView } from './shared/models/domain/domain-view.model';
import { PageResponse } from './shared/models/response/page-response.model';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { tenantsRoutes } from './sub-domain-tenants.route';

export function momentAdapterFactory() {
    return adapterFactory(moment);
};

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

const maskConfig: Partial<NgxMaskConfig> = {
    validation: false,
};

const initApp = (officeTenantsService: OfficeTenantsService, authGuard: AuthGuard, router: Router) => {
    return (): Promise<PageResponse<TenantView>> =>
        officeTenantsService.getTenants({ itemsPerPage: environment.defaultItemsPerPage, sort: "tenantId", sortDir: 'asc' }).toPromise().then((response) => {
        authGuard.isAppInit = true;
        const websiteSlug = officeTenantsService.findSlugNameBaseOnRoute();
        if (websiteSlug) router.config.splice(-1, 0, ...tenantsRoutes);
        return response;
    });
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        CoreModule,
        LayoutModule,
        FormlyModule.forRoot({
            extras: { lazyRender: true },
        }),
        FormlyMaterialModule,
        ReactiveFormsModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
        ServiceWorkerModule.register('sw.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule
    ],
    providers: [
        provideAppInitializer(() => {
            const initializerFn = (initApp)(inject(OfficeTenantsService), inject(AuthGuard), inject(Router));
            return initializerFn();
        }),
        provideEnvironmentNgxMask(maskConfig),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        DatePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
