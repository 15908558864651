import Dexie, { Table } from 'dexie';
import { EventMediaUpload, MediaUpload } from './db-domain.model';
export const dbVersion = 1;

export class DbRepository extends Dexie
{
    mediaUploads!: Table<MediaUpload, string>;
    eventMediaUploads!: Table<EventMediaUpload, string>;
    constructor() {
        super('bringProDb');
        this.version(dbVersion).stores({
            mediaUploads: 'id, created, status',
            eventMediaUploads: 'id, storageItemInstanceId, subscriptionEventId, retries, created'
        });
    }
}

export const db = new DbRepository();
