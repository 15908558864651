import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { UserService } from 'app/core/user/user.service';
import { UserAccountView } from 'app/shared/models/views/user-account-view.model';
import { CustomerChatService } from 'app/modules/customer/chat/customer-chat.service';
import { OfficeChatService } from 'app/modules/office/chat/office-chat.service';
import { OfficeTenantsService } from 'app/modules/office/tenants/office-tenants.service';
import { Router } from '@angular/router';
import { ConversationView, TenantView } from 'app/shared/models/domain/domain-view.model';
import { AuthService } from 'app/core/auth/auth.service';
import { HideNavigationBasedOnRole } from 'app/shared/enums/roles.enum';
import { AlertService } from 'app/shared/services/alert.service';
import { CustomConversationView, NotificationToast } from 'app/shared/models/domain/custom-views.model';

@Component({
    selector: 'futuristic-layout',
    templateUrl: './futuristic.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FuturisticLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    view: UserAccountView;
    isOffice: boolean = false;
    isCustomer: boolean = false;
    tenantView: TenantView
    conversations: CustomConversationView[];
    private _unsubscribeAll: Subject<void> = new Subject<void>();

    /**
     * Constructor
     */
    constructor(
        private _changes: ChangeDetectorRef,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private customerChatService: CustomerChatService,
        private officeChatService: OfficeChatService,
        private officeTenantsService: OfficeTenantsService,
        private router: Router,
        private alertService: AlertService,
        private authService: AuthService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit(): void {
        // Get current slug name 
        this.tenantView = this.officeTenantsService.getTenantDetailsBySlug();
        const userRole = this.authService.userProfile.userRoles;


        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                const tenantSlug: string = this.officeTenantsService.findSlugNameBaseOnRoute();
                const tenantsView: TenantView[] = this.officeTenantsService.getAllTenantsViewItems();
                this.isOffice = navigation.futuristic[0].id === "reports";
                this.isCustomer = navigation.futuristic[0].id === "customer-home";
                ['default', 'compact', 'futuristic', 'horizontal'].map(category => {
                    navigation[category].map((nav, index) => {
                        if (userRole.some(role => HideNavigationBasedOnRole[role.roleName]?.includes(nav.id))) {
                            navigation[category].splice(index, 1);
                        }
                        const tenantMatch = tenantsView.find(tenant => nav?.link?.includes(tenant?.slug));
                        // Modify nav.link if tenant slug is found
                        if (tenantMatch) {
                            nav.link = nav.link.replace(`/${tenantMatch.slug}`, '');
                        }
                
                        // Prepend tenantSlug to nav.link
                        if (tenantSlug && !nav.link.includes(tenantSlug) && this.router.url.includes(tenantSlug)) {
                            nav.link = `/${tenantSlug}${nav.link}`;
                        } 
                        
                        // Modify nav.link if tenantSlug is absent and isOffice is true
                        else if (!tenantSlug && !nav.link.startsWith('/office') && this.isOffice) {
                            nav.link = `/${nav.link.split('/').slice(1).join('/')}`;
                        }
                    })
                });
                this.navigation = navigation;
                if (this.isOffice) this.startChatConnection(this.officeChatService);
                if (this.isCustomer) this.startChatConnection(this.customerChatService);
            });

        // Subscribe to the user service
        // this._userService.user$
        //     .pipe((takeUntil(this._unsubscribeAll)))
        //     .subscribe((user: User) => {
        //         this.user = user;
        //     });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.view = this._userService.account;
        this._changes.detectChanges();
        this._userService.account$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: UserAccountView) => {
                this.view = user;
                this._changes.detectChanges();
                // this.user = this.view.user;
                // this.avatar = this.view.profileMediaUrl;
                // this.initNav();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Start socket connection
     * @param chatService 
     */
    startChatConnection(chatService: CustomerChatService | OfficeChatService): void {
        chatService.startConnection().subscribe(() => {
            /* Get badge notification count */
            const params = new URLSearchParams(this.router.url.split('?')[1]);
            let request = {
                websiteId: Number(params.get('website')) || this.tenantView?.tenantId
            }
            chatService.getConversations(request).subscribe();
        });
        
        // Show chat badge notification
        chatService.chats$.pipe(takeUntil(this._unsubscribeAll)).subscribe((response: CustomConversationView[]) => {
            this.conversations = response;
            this.chatBadgeNotification(response);
        });

        // Show chat toast notification
        chatService.receiveMessage().pipe(takeUntil(this._unsubscribeAll)).subscribe((response) => {
            const { fullUrl, firstName, lastName, messageText, userId, conversationId, messageMediaFullUrl, websiteId } = response;
            const currentUrl = this.router.url;
            let currentConversation = this.conversations?.find(conversation => conversation.id === conversationId);
            if (userId === this.authService?.userProfile?.userProfile?.userId || currentUrl?.includes("/chat") && currentUrl?.split('/chat/')[1]?.split('?')[0] === conversationId) return;
            let mediaUrl;
            const isGroup = currentConversation?.users?.length > 1;
            if (isGroup) {
                if (currentConversation['avatar']) {
                    mediaUrl = currentConversation['avatar'];
                } else {
                    if (this.isCustomer) mediaUrl = "assets/images/bringpro-guy-with-circle.png";
                }
            } else {
                if (fullUrl && this.isOffice) mediaUrl = fullUrl;
                if (this.isCustomer) mediaUrl = "assets/images/bringpro-guy-with-circle.png";
            }
            const conversationName = isGroup ? currentConversation?.name : '';
            const notification: NotificationToast = {
                groupName: conversationName,
                userName: `${firstName} ${lastName}`,
                avatar: mediaUrl,
                message: messageText,
                messageMediaFullUrl,
                conversationId,
                isAdmin: this.isOffice ? true : false,
                websiteId,
                chatService: this.isOffice ? this.officeChatService : this.customerChatService 
            };
            this.alertService.notificationToast(notification);
            chatService.getConversations({websiteId}).subscribe();
        })
    }
    
    /**
     * Chat bridge notification
     */
    chatBadgeNotification(response: ConversationView[]): void {
        let notificationCount: number = response?.filter(conversation => this.isOffice ? conversation?.unreadOfficeMessages : conversation?.unreadMessages)?.length ?? 0;
        
        // Update the futuristic navigation object
        const updatedFuturistic = this.navigation.futuristic.map(item => {
            if (item.id == "chat") {
                if (notificationCount) {
                    return { ...item, badge: { title: String(notificationCount), classes: 'bg-primary px-2 rounded-full text-white' } };
                } else {
                    const { badge, ...itemWithoutBadge } = item;
                    return itemWithoutBadge;
                }
            }
            return item;
        });
        
        // Create a new reference to trigger change detection
        this.navigation = { ...this.navigation, futuristic: updatedFuturistic };
        
        // Trigger change detection manually
        this._changes.detectChanges();
    }

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
