import { environment } from 'environments/environments';
import { Deserializable } from '../deserializable.model';
import { HttpParams } from '@angular/common/http';


export class PageRequest implements Deserializable {
    currentPage: number = 1;
    itemsPerPage: number = environment.defaultItemsPerPage;
    query?: string;
    searchQuery?: string;
    assignedToUserId?: any;
    userID?: any;
    queryRole?: string;
    queryStartDate?: any;
    queryEndDate?: any;
    status?: string;
    attachmentType?: string;
    requestStatus?: string;
    showUnassignedOnly?: string;
    customer?: string;
    salesRep?: string;
    personId?: any;
    documentID?: any;
    // selectedUser: UserProfile;
    queryJobType?: string;
    tabStatus?: string;
    sort?: string;
    sortDir?: string | null;
    eventType?: string;
    slug?: string;
    search?: any;
    usage?: any;
    quoteStatusId?: number;
    roleList?: any;
    clientId: any;
    applicationId?: any;
    quoteID?: any;
    allData: any;
    isExport: any;
    searchParam: any;
    find: { [key: string]: string | number } = {};
    orLike: { [key: string]: string } = {};
    where: { [key: string]: string } = {};
    hideColumns?: string | null;
    showColumns?: string | null;
    fileType?:string;
    noObserverEmit: boolean = false;
    multiSort: { [key: string]: string | number } = {};
    excludedColumns: string[] = [];
    includedColumns: string[] = [];

    deserialize(input: any): this {
        return Object.assign(this, input);
    }

    toParams(): HttpParams {
        let params = new HttpParams()
            .set('itemsPerPage', this.itemsPerPage > 0 ? this.itemsPerPage: 10)
            .set('currentPage', this.currentPage > 0 ? this.currentPage : 1);

        if (this.multiSort && Object.keys(this.multiSort).length > 0){
            Object.keys(this.multiSort).forEach(key => {
                params = params.set(`sort[${key}]`, this.multiSort[key]);
            });
        }
        else if (this.sort) {
            const sortDir = this.sortDir == 'desc' ? this.sortDir : 'asc';
            params = params.set(`sort[${this.sort}]`, sortDir);
        }

        if (this.find) {
            Object.keys(this.find).forEach(key => {
                params = params.set(`find[${key}]`, this.find[key]);
            });
        }

        if (this.orLike) {
            Object.keys(this.orLike).forEach(key => {
                params = params.set(`orLike[${key}]`, this.orLike[key]);
            });
        }

        if (this.fileType) {
            params = params.set('fileType', this.fileType);
        }

        if (this.excludedColumns && this.excludedColumns.length > 0) {
            params = params.set('excludedColumns', this.excludedColumns.join(','));
        }
        
        if (this.includedColumns && this.includedColumns.length > 0) {
            params = params.set('includedColumns', this.includedColumns.join(','));
        }

        return params;
    }
}
