export const environment = {
  production: true,
  isProductionMode: false,
  defaultWebsiteSlug: 'bringpro',
  apiUrl: 'https://prod-api.bringpro.com/',
  captcha_key: '6Lev-eUjAAAAAOMjuMIU37kDX3q6GWmT-NNp-crS',
  google_client_id: "1054376475058-bkinspub1qm7o4m7oqa5dlo2o9ru6v3e.apps.googleusercontent.com",
  apple_client_id: "com.bringpro.app.prod.login",
  legacyApiUrl: 'https://m2.bringpro.com/',
  legacyAppUrl: 'https://m3.bringpro.com/',
  appUrl: "https://app.bringpro.com/",
  legacyLoginEnabled: true,
  defaultWebsiteId: 55,
  mapKey: "AIzaSyD_6OT7i9lY2pyKDvitJoFgg346I8nZrQM",
  defaultItemsPerPage: 25,
  firebaseConfig : {
    apiKey: "AIzaSyAlPBDyumje9cMltja-G7ixne2MLHSl4T0",
    authDomain: "prod-bringpro.firebaseapp.com",
    projectId: "prod-bringpro",
    storageBucket: "prod-bringpro.firebasestorage.app",
    messagingSenderId: "742408432388",
    appId: "1:742408432388:web:a8cc3a5961eb4b4ee3fe51",
    measurementId: "G-P4PHTLSXYZ"
  }
};