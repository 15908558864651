import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { forkJoin } from 'rxjs';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { WebsiteSettingsViewService } from './shared/services/settings.service';
import { WebsiteSettingsView } from './shared/models/domain/domain-view.model';
import { PageResponse } from './shared/models/response/page-response.model';
import { OfficeTenantsService } from './modules/office/tenants/office-tenants.service';
import { environment } from 'environments/environments';

export const InitialDataResolver: ResolveFn<any> =
(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const messagesService: MessagesService = inject(MessagesService);
    const navigationService: NavigationService = inject(NavigationService);
    const notificationsService: NotificationsService = inject(NotificationsService);
    const quickChatService: QuickChatService = inject(QuickChatService);
    const shortcutsService: ShortcutsService = inject(ShortcutsService);
    return forkJoin([
        navigationService.get(),
        messagesService.getAll(),
        notificationsService.getAll(),
        quickChatService.getChats(),
        shortcutsService.getAll()
    ]);
};

export const OfficeDataResolver: ResolveFn<any> =
(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const messagesService: MessagesService = inject(MessagesService);
    const navigationService: NavigationService = inject(NavigationService);
    const notificationsService: NotificationsService = inject(NotificationsService);
    const quickChatService: QuickChatService = inject(QuickChatService);
    const shortcutsService: ShortcutsService = inject(ShortcutsService);
    return forkJoin([
        navigationService.getOfficeNavigation(),
        messagesService.getAll(),
        notificationsService.getAll(),
        quickChatService.getChats(),
        shortcutsService.getAll()
    ]);
};

export const DriverDataResolver: ResolveFn<any> =
(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const messagesService: MessagesService = inject(MessagesService);
    const navigationService: NavigationService = inject(NavigationService);
    const notificationsService: NotificationsService = inject(NotificationsService);
    const quickChatService: QuickChatService = inject(QuickChatService);
    const shortcutsService: ShortcutsService = inject(ShortcutsService);
    return forkJoin([
        navigationService.getDriverNavigation(),
        messagesService.getAll(),
        notificationsService.getAll(),
        quickChatService.getChats(),
        shortcutsService.getAll()
    ]);
};

export const WebsiteSettingsDataResolver: ResolveFn<PageResponse<WebsiteSettingsView>> =
(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const websiteSettingsService = inject(WebsiteSettingsViewService);
    const officeTenantsService = inject(OfficeTenantsService);
    const { token, source, slug } = route?.queryParams;
    let websiteId = officeTenantsService.getTenantDetailsBySlug()?.tenantId;
    if (token && source && slug) websiteId = officeTenantsService.getAllTenantsViewItems()?.find(website => website.slug === slug)?.tenantId;
    return websiteSettingsService.getWebsiteSettings(websiteId || environment.defaultWebsiteId);
}
