import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Route, Router } from '@angular/router';
import { FuseNavigationItem } from '@fuse/components/navigation';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
        private router: Router) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    emitNavigationChange() {
        this._navigation.next({} as Navigation);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }

    getOfficeNavigation(): Observable<Navigation> {
        let defaultNavigation: FuseNavigationItem[] = [
            {
                id: 'reports',
                title: 'Reports',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/office/reports'
            },
            {
                id: 'quotes',
                title: 'Quotes',
                type: 'basic',
                icon: 'heroicons_outline:light-bulb',
                link: '/office/quotes'
            },
            {
                id: 'invoices',
                title: 'Invoices',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: '/office/invoices'
            },
            {
                id: 'delivery-jobs',
                title: 'Jobs',
                type: 'basic',
                icon: 'heroicons_outline:truck',
                link: '/office/delivery-jobs/all'
            },
            {
                id: 'warehouse-jobs',
                title: 'Inventory',
                type: 'basic',
                icon: 'heroicons_outline:office-building',
                link: '/office/warehouse-jobs'
            },
            {
                id: 'users',
                title: 'Users',
                type: 'basic',
                icon: 'heroicons_outline:users',
                link: '/office/users'
            },
            {
                id: 'tenants',
                title: 'Tenants',
                type: 'basic',
                icon: 'heroicons_outline:ticket',
                link: '/office/tenants'
            },
            {
                id: 'developer',
                title: 'Developer',
                type: 'basic',
                icon: 'heroicons_outline:code',
                link: '/office/developer'
            },
            {
                id: 'chat',
                title: 'Chat',
                type: 'basic',
                icon: 'heroicons_outline:chat-alt-2',
                link: '/office/chat'
            },
            {
                id: 'qrReader',
                title: 'Scanner',
                type: 'basic',
                icon: 'heroicons_outline:qrcode',
                link: '/office/scanner'
            },
        ];
        let navigation: Navigation = {
            default: defaultNavigation,
            compact: defaultNavigation,
            futuristic: defaultNavigation,
            horizontal: defaultNavigation
        } as Navigation;
        return of(navigation).pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );;
    }

    getDriverNavigation(): Observable<Navigation> {
        let defaultNavigation: FuseNavigationItem[] = [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'basic',
                icon: 'heroicons_outline:home',
                link: '/driver/dashboard'
            },
            {
                id: 'reports',
                title: 'Reports',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/driver/reports'
            },
            {
                id: 'jobs',
                title: 'Jobs',
                type: 'basic',
                icon: 'heroicons_outline:truck',
                link: '/driver/jobs/pending'
            },
            //TODO Enable after setting up schedule
            /*{
                id: 'schedule',
                title: 'Schedule',
                type: 'basic',
                icon: 'heroicons_outline:map',
                link: '/driver/schedule'
            },*/
            {
                id: 'settings',
                title: 'Settings',
                type: 'basic',
                icon: 'heroicons_outline:cog',
                link: '/driver/settings'
            },
        ];
        let navigation: Navigation = {
            default: defaultNavigation,
            compact: defaultNavigation,
            futuristic: defaultNavigation,
            horizontal: defaultNavigation
        } as Navigation;
        return of(navigation).pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );;
    }
}
