import { FormGroup } from "@angular/forms";
import { PageRequest } from "../models/request/page-request.model";
import { SelectOption } from "../models/shared/select-option.model";
import { CamelCaseToWordsPipe } from "../pipes/camel-case-to-words.pipe";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { environment } from "environments/environments";
import { AsyncDataTableConfig } from "../components/data-table-async/data-table-async.types";
import { PricingModel } from "../enums/pricing-model.enum";
import { ErrorCodes } from 'app/shared/enums/error-code.enum';
import { AlertService } from 'app/shared/services/alert.service';

/**
 * Converts an enumeration to an array of SelectOption objects with descriptive labels.
 * 
 * @template T - The type of the enumeration.
 * @param enumeration - The enumeration to convert.
 * @param separatorRegex - Optional regular expression used to separate words in the labels. Defaults to /_/g.
 * @returns An array of SelectOption objects.
 */
export function enumToDescriptedArray<T>(enumeration: T, separatorRegex: RegExp = /_/g): SelectOption[] {
    const camelCasePipe = new CamelCaseToWordsPipe();
    return (Object.keys(enumeration) as Array<keyof T>)
        .filter(key => isNaN(Number(key)))
        .filter(key => typeof enumeration[key] === "number" || typeof enumeration[key] === "string")
        .map(key => ({
            value: enumeration[key],
            label: camelCasePipe.transform(String(key).replace(separatorRegex, ' '))?.trim(),
            extra: enumeration[key]
        } as SelectOption));
}

/**
 * Generates a GUID (Globally Unique Identifier).
 * 
 * @returns A string representing the generated GUID.
 */
export const generateGuid = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * Groups an array of elements by a specified predicate function.
 * 
 * @template T The type of elements in the array.
 * @param {T[]} array The array to be grouped.
 * @param {(value: T, index: number, array: T[]) => string} predicate The function used to determine the grouping key for each element.
 * @returns {{ [key: string]: T[] }} An object where the keys are the grouping keys and the values are arrays of elements that belong to each group.
 */
export const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
    array.reduce((acc, value, index, array) => {
        (acc[predicate(value, index, array)] ||= []).push(value);
        return acc;
    }, {} as { [key: string]: T[] });


/**
 * Formats a date into a string representation yyyy-MM-dd HH:mm:ss
 * @param date - The date to be formatted.
 * @returns The formatted date string.
 */
export const getFormattedDate = (date: Date): string => {
    return date?.toISOString().replace('Z','').replace('T',' ').replace('.000','');
}

/**
 * Formats a date into a string representation yyyy-MM-dd HH:mm:ss
 * @param date - The date to be formatted.
 * @returns The formatted date string.
 */
export const getCurrentTimestamp = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
}


/**
 * Reloads the current page while preserving the query parameters.
 * 
 * @param router - The router instance.
 * @param route - The activated route instance.
 */
export const reloadPage = (router: Router, route: ActivatedRoute, tenantSlug: string) => {
  let url = router.url.split('?')[0];
  let params = route.snapshot.queryParams;
  router.navigate([tenantSlug ? `${tenantSlug}/reload` : '/reload'], { replaceUrl: true }).then(() => {
      router.navigate([url], { queryParams: params, replaceUrl: true });
  });
}


export const createParamsFromForm = (request: PageRequest, form: FormGroup, table: AsyncDataTableConfig) => {
    let value = form.value;
    let params = {} as Params;
    Object.keys(value).forEach(key => {
      if (value[key] && value[key] instanceof Date) {
        params[key] = value[key].toISOString();
      }
      else if (value[key]) {
        params[key] = value[key] instanceof Object ? value[key]?.userId : value[key];
      }
    });

    if (request.itemsPerPage !== environment.defaultItemsPerPage && request.itemsPerPage > 0) {
      params['itemsPerPage'] = request.itemsPerPage;
    }

    if (request.currentPage > 1) {
      params['currentPage'] = request.currentPage;
    }

    if (request.sort && request.sort !== table.defaultSort) {
      params['sort'] = request.sort;
    }

    if (request.sortDir && request.sortDir !== table.defaultSortOrder) {
      params['sortDir'] = request.sortDir;
    }
    return params;
  }


export const checkWarehousePricingModels = (pricingModel: number): boolean => {
  return pricingModel === PricingModel.baseRatePlusPerMinAllWayPoints
      || pricingModel === PricingModel.baseRatePlusPerMinLastWayPoint
      || pricingModel === PricingModel.baseRatePlusPerMinFirstWayPoint;
}

export const getFileExtensionColor = (file: string): string => {
  const extension = file.split('.').pop()?.toUpperCase();
  switch (extension) {
      case 'PDF':
          return 'bg-red-600';
      case 'DOC':
      case 'DOCX':
          return 'bg-blue-600';
      case 'XLSX':
      case 'CSV':
      case 'XLS':
          return 'bg-green-600';
      case 'JPG':
      case 'PNG':
      case 'JPEG':
          return 'bg-amber-600';
      case 'TXT':
          return 'bg-gray-600';
      default:
          return 'bg-gray-500';
  }
}

export const convertImageToBase64 = (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          if (ctx) {
              ctx.drawImage(img, 0, 0);
              resolve(canvas.toDataURL('image/jpg'));
          }
      };
      img.onerror = reject;
      img.src = url;
  });
}

export const errorHandler = (errorCode: number, alertService: AlertService) => {
  switch (errorCode) {
    case ErrorCodes.UserNotFound:
      alertService.errorToast("User Not Found", "We couldn't find any user with the given details. Please check the information and try again.");
      break;
    case ErrorCodes.WrongPassword:
      alertService.errorToast("Incorrect Password", "The password you entered is incorrect. Please try again or reset your password.");
      break;
    case ErrorCodes.DuplicatedPhone:
      alertService.errorToast("Duplicate Phone Number", "The phone number you entered is already registered. Please use a different number or log in.")
      break;
    case ErrorCodes.SocialUserNotFound:
      alertService.errorToast("Social User Not Found", "We couldn't find a social account linked with the phone number provided. Please try another method.");
      break;
    case ErrorCodes.NoPhoneFound:
      alertService.errorToast("Phone Number Not Found", "No phone number was found. Please ensure you entered it correctly or sign up first.");
      break;
    case ErrorCodes.NoUserFound:
      alertService.errorToast("No User Found", "We couldn't find any user associated with the provided information. Please check and try again.");
      break;
    case ErrorCodes.NoTokenFound:
      alertService.errorToast("No Token Found", "There was an issue with the authentication token. Please try logging in again.");
      break;
    case ErrorCodes.InvalidPhone:
      alertService.errorToast("Invalid Phone Number", "The phone number format is invalid. Please enter a valid phone number.");
      break;
  }
}

export const hexToRgb = (hex: string, opacity?: number): string => {
  hex = hex.replace('#', '');
  // Parse the hex values and convert them to decimal
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return the RGB formatted string
  if (opacity) {
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}