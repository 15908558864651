/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'customer-home',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard'
    },
    {
        id: 'quotes',
        title: 'Quotes',
        type: 'basic',
        icon: 'heroicons_outline:light-bulb',
        link: '/quotes'
    },
    {
        id: 'invoices',
        title: 'Open Invoices',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/invoices'
    },
    {
        id: 'jobs',
        title: 'Jobs',
        type: 'basic',
        icon: 'heroicons_outline:truck',
        link: '/jobs'
    },
    {
        id: 'sidemarks',
        title: 'Inventory',
        type: 'basic',
        icon: 'heroicons_outline:office-building',
        link: '/sidemarks'
    },
    {
        id: 'billing',
        title: 'Billing',
        type: 'basic',
        icon: 'heroicons_outline:credit-card',
        link: '/billing'
    },
    {
        id: 'invite',
        title: 'Invite Friends',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/invite'
    },
    {
        id: 'help',
        title: 'Help Center',
        type: 'basic',
        icon: 'heroicons_outline:question-mark-circle',
        link: '/help'
    },
    {
        id: 'chat',
        title: 'Chat',
        type: 'basic',
        icon: 'heroicons_outline:chat-alt-2',
        link: '/chat'
    }
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
