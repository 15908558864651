export enum PricingModel {
    flatRate = 1,
    perMinuteWithMinimum = 2,
    flatRatePlusPerMin = 3,
    flatRateOneWay = 4,
    baseRatePlusPerMinLastWayPoint = 5,
    baseRatePlusPerMinFirstWayPoint = 6,
    baseRatePlusPerMinAllWayPoints = 7,
}

