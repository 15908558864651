import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { JobsType } from 'app/shared/enums/job-type.enum';
import { DriverAvailabilityView, TenantDriverView, TenantView } from 'app/shared/models/domain/domain-view.model';
import { JobTimeSlot, Territory, TerritoryZip, UserTeam, UserWebsite, WebsiteTeam } from 'app/shared/models/domain/domain.model';
import { BulkRequest } from 'app/shared/models/request/bulk-request.model';
import { PageResponse } from 'app/shared/models/response/page-response.model';
import { CoreApiService } from 'app/shared/services/common/core-api.service';
import { environment } from 'environments/environments';
import moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OfficeTenantsService {

    baseUrl = environment.apiUrl;
    tenantView: TenantView[] = [];
    httpOptions: any = {
        withCredentials: true,
        observe: 'response' as 'response'
    };

    private _tenants: BehaviorSubject<PageResponse<TenantView>> = new BehaviorSubject(null);
    private _tenant: BehaviorSubject<TenantView> = new BehaviorSubject(null);
    private _tenantDrivers: BehaviorSubject<PageResponse<TenantDriverView>> = new BehaviorSubject(null);
    private _tenantTeams: BehaviorSubject<PageResponse<WebsiteTeam>> = new BehaviorSubject(null);
    private _territory: BehaviorSubject<PageResponse<TerritoryZip>> = new BehaviorSubject(null);
    private _schedule: BehaviorSubject<PageResponse<DriverAvailabilityView>> = new BehaviorSubject(null);
    private _jobType: BehaviorSubject<JobsType> = new BehaviorSubject(null);

    constructor(
        private _httpClient: HttpClient,
        private _router: Router
    ) {
    }

    get tenants$(): Observable<PageResponse<TenantView>> {
        return this._tenants.asObservable();
    }

    get tenant$(): Observable<TenantView> {
        return this._tenant.asObservable();
    }

    get tenantDrivers$(): Observable<PageResponse<TenantDriverView>> {
        return this._tenantDrivers.asObservable();
    }

    get tenantTeams$(): Observable<PageResponse<WebsiteTeam>> {
        return this._tenantTeams.asObservable();
    }

    get territory$(): Observable<PageResponse<TerritoryZip>> {
        return this._territory.asObservable();
    }

    get schedule$(): Observable<PageResponse<DriverAvailabilityView>> {
        return this._schedule.asObservable();
    }
    
    get jobType$(): Observable<JobsType> {
        return this._jobType.asObservable();
    }

    setJobType(jobType: JobsType): void {
        this._jobType.next(jobType);
    }

    tenantSlugNavigate = (redirectUrl: string, queryParams: Params | null = null) => {
        const tenantSlug = this.findSlugNameBaseOnRoute();
        tenantSlug ? this._router.navigate([tenantSlug + '/' + redirectUrl], { queryParams }) : this._router.navigate([redirectUrl], { queryParams });
    }

    saveTimeslot(slot: JobTimeSlot) {
        var task: Observable<JobTimeSlot>;
        if (slot.id) {
            task = this._httpClient.put<any>(`${this.baseUrl}api/admin/job/timeslot/${slot.id}`, slot)
        } else {
            task = this._httpClient.post<any>(`${this.baseUrl}api/admin/job/timeslot`, slot)
        }

        return task.pipe(
            map((response: any) => {
                let ret = response.item as JobTimeSlot;
                return ret;
            })
        );
    }

    initTimeslot(view: DriverAvailabilityView, date: Date, useDefaultId: boolean = false): Observable<JobTimeSlot> {
        var task: Observable<JobTimeSlot>;
        if (view.defaultId) {
            if (useDefaultId) {
                task = this._httpClient.get<any>(`${this.baseUrl}api/admin/job/timeslot/${view.defaultId}`, {})
            } else {
                task = this._httpClient.get<any>(`${this.baseUrl}api/admin/job/timeslot/${view.timeSlotId}`, {})
            }
        } else {
            var theDate = moment(date);
            var newSlot: JobTimeSlot = {
                scheduleType: view.scheduleType
                , timeStart: view.timeStart
                , timeEnd: view.timeEnd
                , capacity: view.capacity
                , defaultId: view.timeSlotId
                , dayOfWeek: view.dayOfWeek
                , teamId: view.teamId
                , dayOfWeekId: view.dayOfWeekId
                , driverUserId: view.driverUserId
                , teamSlotId: view.teamSlotId
                , date: theDate.format("YYYY-MM-DD")

            } as JobTimeSlot;

            task = this._httpClient.post<any>(`${this.baseUrl}api/admin/job/timeslot`, newSlot);
        }

        return task.pipe(
            map((response: any) => {
                let ret = response.item as JobTimeSlot;
                return ret;
            })
        );

    }

    getSchedule(tenantId: any, viewDate: Date, view: string, driverUserIds: string[] | null): Observable<PageResponse<DriverAvailabilityView>> {

        let params = {
            currentPage: 1
            , itemsPerPage: 1000
            , 'find[websiteId]': tenantId
        };

        const mmt = moment(viewDate);

        switch (view) {
            case 'month':
                params['find[ScheduleYear]'] = mmt.year();
                params['find[ScheduleMonth]'] = mmt.month() + 1;
                break;

            case 'week':

                let weekStart = mmt.clone().startOf('week');
                let weekEnd = mmt.clone().endOf('week');
                params['find[ScheduleDay:gte]'] = weekStart.format('YYYY-MM-DD');
                params['find[ScheduleDay:lte]'] = weekEnd.format('YYYY-MM-DD');
                break;

            case 'day':
                params['find[ScheduleDay]'] = mmt.format('YYYY-MM-DD');
                break;
        }

        if (driverUserIds?.length) {
            params['find[driverUserId:in]'] = driverUserIds.join(',');
        }

        return this._httpClient.get<any>(`${this.baseUrl}api/admin/view/tenant/schedule`, { params: params })
            .pipe(
                map((response: any) => {
                    let ret = response as PageResponse<DriverAvailabilityView>;
                    this._schedule.next(ret);
                    return ret;
                })
            );
    }

    getParams(request, params): any {
        params['itemsPerPage'] = request.itemsPerPage ? request.itemsPerPage : 10;
        params['currentPage'] = request.currentPage ? request.currentPage : 1;
        if (request.find && request.find?.slug ) {
            params['find[slug]'] = request.find.slug;
        }
        if (request.sort) {
            let sortColumn = request.sort;
            switch (sortColumn) {
                case "item":
                    sortColumn = 'name';
                    break;
            }
            params[`sort[${sortColumn}]`] = request.sortDir;
        } else {
            params['sort[name]'] = 'desc';
        }
        return params;
    }

    getTenants(request): Observable<PageResponse<TenantView>> {
        let params = {};
        params = this.getParams(request, params);
        return this._httpClient.get<any>(`${this.baseUrl}api/admin/view/tenants`, { params })
            .pipe(
                map((response: any) => {
                    this.tenantView = [];
                    let ret = response as PageResponse<TenantView>;
                    this._tenants.next(ret);
                    this.tenantView = ret?.items?.length ? ret.items : [];
                    return ret;
                })
            );
    }
    
    getUserWebsite(request): Observable<PageResponse<UserWebsite>> {        
        return this._httpClient.get<any>(`${this.baseUrl}api/admin/user-website`, { params: request.toParams() });
    }

    setBulkUserWebsite(request: BulkRequest<UserWebsite>): Observable<UserWebsite[]> {
        return this._httpClient.post<PageResponse<UserWebsite>>(`${this.baseUrl}api/admin/user-website/bulk`, request)
            .pipe(map((res) => res?.items));
    }

    /**
     * This function is use for get all websites
     * @returns 
     */
    getAllTenantsViewItems() : TenantView[] { 
        return this.tenantView?.length ? this.tenantView : [];
    }

    /**
     * This function is use for get website based on router slug
     * @returns 
     */
    findSlugNameBaseOnRoute(): string {
        return this.tenantView?.find(item => item.slug === window.location.pathname?.split('/')?.[1])?.slug;
    }

    /**
     * This function is use for get website based on website id
     * @param websiteId 
     * @returns 
     */
    findWebsiteBasedOnWebsiteId(websiteId: number): TenantView {
        return this.tenantView?.find(item => item.tenantId === websiteId);
    }

    /**
     * This function is use for get slug based on router slug
     * @returns 
     */
    getTenantDetailsBySlug(): TenantView {
        const tenantSlug = this.findSlugNameBaseOnRoute();
        const tenantsView = this.getAllTenantsViewItems();
        return tenantsView.find(item => item?.slug.includes(tenantSlug));
    }

    getTenant(tenantId): Observable<TenantView> {
        return this._httpClient.get<any>(`${this.baseUrl}api/admin/view/tenants/${tenantId}`, {})
            .pipe(
                map((response: any) => {
                    let ret = response.item as TenantView;
                    this._tenant.next(ret);
                    return ret;
                })
            );
    }

    getTenantDrivers(tenantId: number, request): Observable<PageResponse<TenantDriverView>> {
        let params = {};
        params = this.getParams(request, params);
        params["find[tenantId]"] = tenantId;
        const httpOptionsLocal = { params: params };
        return this._httpClient.get<any>(`${this.baseUrl}api/admin/view/tenant/drivers`, httpOptionsLocal)
            .pipe(
                map((response: any) => {
                    let ret = response as PageResponse<TenantDriverView>;
                    this._tenantDrivers.next(ret);
                    return ret;
                })
            );
    }

    getTenantTeams(tenantId: number, request): Observable<PageResponse<WebsiteTeam>> {
        let params = {};
        params = this.getParams(request, params);
        params["find[websiteId]"] = tenantId;
        const httpOptionsLocal = { params: params };
        return this._httpClient.get<any>(`${this.baseUrl}api/admin/view/website-team`, httpOptionsLocal)
            .pipe(
                map((response: any) => {
                    let ret = response as PageResponse<WebsiteTeam>;
                    this._tenantTeams.next(ret);
                    return ret;
                })
            );
    }

    getTerritory(territoryId: any): Observable<PageResponse<TerritoryZip>> {

        var q = {
            'find[territoryId]': territoryId
            , currentPage: 1
            , itemsPerPage: 200
            , 'sort[zip]': 'asc'
        }

        return this._httpClient.get<any>(`${this.baseUrl}api/admin/territory-zip`, { params: q })
            .pipe(
                map((response: any) => {
                    let ret = response as PageResponse<TerritoryZip>;
                    this._territory.next(ret);
                    return ret;
                })
            );
    }

    deleteTerritoryZip(zip: TerritoryZip) {
        var task: Observable<TerritoryZip> = this._httpClient.delete<any>(`${this.baseUrl}api/admin/territory-zip/${zip.id}`, {});

        return task.pipe(
            switchMap((response: any) => {
                return this.getTerritory(zip.territoryId);
            })
            , catchError((err) => {
                console.error("error deleting zip", err);
                return of(null)
            })
        );
    }

    saveTerritoryZip(zip: TerritoryZip) {
        var task: Observable<TerritoryZip>;
        if (zip.id) {
            task = this._httpClient.put<any>(`${this.baseUrl}api/admin/territory-zip/${zip.id}`, zip)
        } else {
            task = this._httpClient.post<any>(`${this.baseUrl}api/admin/territory-zip`, zip)
        }
        return task.pipe(
            switchMap((response: any) => {
                return this.getTerritory(zip.territoryId);
            })
            , catchError((err) => {
                console.error("error saving zip", err);
                return of(null)
            })
        );
    }

    saveTeam(team: WebsiteTeam) {
        var task: Observable<WebsiteTeam>;
        if (team.id) {
            task = this._httpClient.put<any>(`${this.baseUrl}api/admin/website-team/${team.id}`, team)
        } else {
            task = this._httpClient.post<any>(`${this.baseUrl}api/admin/website-team`, team)
        }

        return task.pipe(
            switchMap((response: any) => {
                return this.getTenantTeams(team.websiteId, {
                    currentPage: 1
                    , itemsPerPage: environment.defaultItemsPerPage
                    , sort: "name"
                    , sortDir: "ASC"
                });
            })
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class AdminUserTeamService extends CoreApiService<UserTeam> {
    constructor(httpClient: HttpClient) {
        super(httpClient, 'admin/user-team');
    }
}

@Injectable({
    providedIn: 'root',
})
export class AdminTerritoryService extends CoreApiService<Territory> {
    constructor(httpClient: HttpClient) {
        super(httpClient, 'admin/territory');
    }
}
