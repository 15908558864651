<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions" class="avatar-image">
    <span class="relative">
        <img class="w-12 h-12 rounded-full bg-gray-300" *ngIf="showAvatar && userView?.profileMediaUrl" [src]="userView?.profileMediaUrl"/>
        <mat-icon *ngIf="!showAvatar || !userView?.profileMediaUrl"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full" [ngClass]="{'mr-px mb-px': !showAvatar,
                          'bg-green-500': isDriver && hasActiveShift,
                          'bg-red-500': isDriver && !hasActiveShift,
                        }"></span>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{userView?.user?.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item [routerLink]="tenantSlug ? ['/' + tenantSlug + '/profile'] : ['/profile']">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <button *ngIf="isAdmin" mat-menu-item [routerLink]="tenantSlug ? ['/' + tenantSlug + '/office'] : ['/office']">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Back Office</span>
    </button>
    <button *ngIf="isDriver" mat-menu-item [routerLink]="tenantSlug ? ['/' + tenantSlug + '/driver'] : ['/driver']">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Driver Dashboard</span>
    </button>
    <button mat-menu-item (click)="startShift()" *ngIf="isDriver && !hasActiveShift">
        <mat-icon [svgIcon]="'heroicons_outline:truck'"></mat-icon>
        <span>Start Shift</span>
    </button>
    <button mat-menu-item (click)="endShift()" *ngIf="isDriver && hasActiveShift">
        <mat-icon [svgIcon]="'heroicons_outline:truck'"></mat-icon>
        <span>End Shift</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<!-- <mat-menu class="user-status-menu" #userStatus="matMenu">
    <button mat-menu-item (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu> -->
