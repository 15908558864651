import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
import { environment } from 'environments/environments';
import { UserAccountView } from 'app/shared/models/views/user-account-view.model';
import { ContactUsRequest, ResendSmsTokenRequest, SmsTokenRequest } from 'app/shared/models/request/auth-api-requests.model';
import { PageResponse } from 'app/shared/models/response/page-response.model';
import { AspNetUser, Help, UserProfile } from 'app/shared/models/domain/domain.model';
import { UserService } from '../user/user.service';
import { AuthUtils } from './auth.utils';
import { AppleUser, GoogleUser } from 'app/shared/models/domain/custom-views.model';

@Injectable()
export class AuthService {
    private authenticated: boolean;
    private baseUrl = environment.apiUrl;
    private appleUserInfo: BehaviorSubject<AppleUser> = new BehaviorSubject<AppleUser>(null);
    private googleUserInfo: BehaviorSubject<GoogleUser> = new BehaviorSubject<GoogleUser>(null);
    httpOptions: object = {
        withCredentials: true,
        observe: 'response' as 'response'
    };

    private requestKey: string = 'bringpro-current-user';
    user: UserProfile;
    constructor(
        private _httpClient: HttpClient,
        private userService: UserService
    ) {
        this.authenticated = this.accessToken && this.accessToken !== '';
    }


    set accessToken(token: string) {
        localStorage.setItem('bringpro.accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('bringpro.accessToken') ?? '';
    }

    set profilePictureUrl(url: string) {
        localStorage.setItem('bringpro.profilePictureUrl', url);
    }

    get profilePictureUrl(): string {
        return localStorage.getItem('bringpro.profilePictureUrl') ?? '';
    }

    set userProfile(user: UserAccountView) {
        localStorage.setItem('bringpro.userProfile', JSON.stringify(user));
    }

    get userProfile(): UserAccountView {
        const user = localStorage.getItem('bringpro.userProfile');
        if (!user || user?.trim() === '') {
            return null;
        }

        try {
            return JSON.parse(user);
        }
        catch (e) {
            return null;
        }
    }

    get appleUserInfo$(): Observable<AppleUser> {
        return this.appleUserInfo.asObservable();
    }
    
    get googleUserInfo$(): Observable<GoogleUser> {
        return this.googleUserInfo.asObservable();
    }

    setAppleUserInfo(userInfo : AppleUser): void {
        this.appleUserInfo.next(userInfo);
    }
    
    setGoogleUserInfo(userInfo : GoogleUser): void {
        this.googleUserInfo.next(userInfo);
    }

    forgotPassword(payload: any): Observable<any> {
        return this._httpClient.post(`${this.baseUrl}api/auth/forgot-password`, payload);
    }

    getFaq(currentPage: number, pageSize: number): Observable<Help[]> {
        return this._httpClient.get<Help[]>(`${this.baseUrl}api/help?currentPage=${currentPage}&itemsPerPage=${pageSize}`)
            .pipe(
                map((response: any) => {
                    return response.items;
                })
            );
    }

    resetPassword(payload: any): Observable<any> {
        return this._httpClient.post(`${this.baseUrl}api/auth/reset-password`, payload);
    }


    signIn(payload: any): Observable<UserAccountView> {
        payload.isCustomer = true;
        payload.withCredentials = true;
        payload.source = "Customer";

        return this._httpClient.post(`${this.baseUrl}api/auth/login`, payload).pipe(
            map((response: UserAccountView) => {
                this.setUserProfile(response);
                return response;
            })
        );
    }

    setUserProfile(response: UserAccountView) {
        this.authenticated = response?.authToken?.token ? true : false;
        this.userProfile = response;
        this.accessToken = response?.authToken?.token ?? '';
        this.profilePictureUrl = response?.profileMediaUrl;
        this.userService.account = response;
    }

    refreshToken(): Observable<boolean> {
        return this._httpClient.post(`${this.baseUrl}api/auth/refresh`, {}).pipe(
            switchMap((response: UserAccountView) => {
                if (response) {
                    this.setUserProfile(response);
                    return of(true);
                }

                return of(false);
            })
        );
    }

    legacySignIn(): void {
        if (!environment.legacyLoginEnabled) return;
        this._httpClient.post(`${environment.legacyApiUrl}api/public/auth/jwt`, {}, this.httpOptions).subscribe({
            next: (response) => {
                if (response) {

                }
            },
            error: (error) => {
                console.error(error);
            }
        })
    }

    signOut(): Observable<boolean> {
        // Remove the access token from the local storage
        localStorage.removeItem('bringpro.accessToken');
        // Set the authenticated flag to false
        this.authenticated = false;
        localStorage.removeItem('authenticated');
        localStorage.removeItem('bringpro.userProfile');
        localStorage.removeItem('bringpro.account');
        localStorage.removeItem('bringpro.profilePictureUrl');

        // Return the observable
        return of(true);
    }


    signUp(payload: any): Observable<UserAccountView> {
        payload.source = "Customer";
        return this._httpClient.post<UserAccountView>(`${this.baseUrl}api/auth/register`, payload).pipe(map((response) => {
            this.setUserProfile(response);
            return response;
        }));
    }

    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    getLocalUser(): Observable<UserProfile> {
        return localStorage.get(this.requestKey)
          .pipe(
            map(data => {
              const userProfile = data;
            //   this.user = userProfile ? userProfile : {} as UserProfile;
              return userProfile;
            })
          );
      }

      get localUser(): UserProfile {

        var raw = localStorage.getItem(this.requestKey);

        if (raw) {
            return JSON.parse(raw) as UserProfile;
        }

        return null;
    }

    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this.authenticated && !AuthUtils.isTokenExpired(this.accessToken)) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }
        return of(false);
    }

    contactUs(payload: ContactUsRequest): Observable<boolean> {
        return this._httpClient.post<PageResponse<boolean>>(`${this.baseUrl}api/contact-us`, payload).pipe(
            map((response: PageResponse<boolean>) => {
                return response.item;
            }));
    }

    signInWithGoogle(idToken: string, recaptchaToken: string, slug: string): Observable<UserAccountView> {
        var googlePayload = {
            idToken: idToken,
            slug,
            recaptchaToken: recaptchaToken
        }

        return this._httpClient.post<UserAccountView>(`${this.baseUrl}api/auth/google`, googlePayload).pipe(
            map((response) => {
                this.setUserProfile(response);
                return response;
            })
        );
    }
    
    signInWithApple(applePayload: AppleUser): Observable<UserAccountView> {
        return this._httpClient.post(`${this.baseUrl}api/auth/apple`, applePayload).pipe(
            map((response: UserAccountView) => {
                applePayload.email = response?.user?.email;
                applePayload.firstName = response?.userProfile?.firstName || applePayload.firstName;
                applePayload.lastName = response?.userProfile?.lastName || applePayload.lastName;
                this.setAppleUserInfo(applePayload);
                this.setUserProfile(response);
                return response;
            })
        );
    }

    resendSmsToken(request: ResendSmsTokenRequest): Observable<boolean> {
        return this._httpClient.post<boolean>(`${this.baseUrl}api/sms/resend`, request).pipe(
            map(response => {
                return response;
            }));
    }

    verifyUser(request: SmsTokenRequest): Observable<UserAccountView> {
        return this._httpClient.post<UserAccountView>(`${this.baseUrl}api/sms/confirm`, request).pipe(
            map(response => {
                this.setUserProfile(response);
                return response;
            }));
    }

    deleteUserAccount(): Observable<AspNetUser> {
        return this._httpClient.delete<AspNetUser>(`${this.baseUrl}api/auth/delete-user`);
    }
}
