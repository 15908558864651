import { EventEmitter, Injectable } from "@angular/core";
import { FuseNavigationItem } from "@fuse/components/navigation";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { PageLayoutSettings, ParentDrawerRequest, ParentHeaderRequest } from "./page-layout.types";

@Injectable({
    providedIn: 'root',
})

export class PageLayoutService {

    private parentDrawerSubject = new Subject<any>();
    busy$: EventEmitter<boolean> = new EventEmitter<boolean>();
    parentData$: EventEmitter<any> = new EventEmitter<any>();

    private _layoutSettings: BehaviorSubject<PageLayoutSettings> = new BehaviorSubject<PageLayoutSettings>(null);
    private _layoutHeader: BehaviorSubject<ParentHeaderRequest | false> = new BehaviorSubject<ParentHeaderRequest | false>(null);
    private _layoutNav: EventEmitter<FuseNavigationItem | false> = new EventEmitter<FuseNavigationItem | false>(null);
    private _parentDrawer: EventEmitter<ParentDrawerRequest | false> = new EventEmitter<ParentDrawerRequest | false>();

    get layoutSettings$() {
        return this._layoutSettings.asObservable();
    }

    get layoutHeader$() {
        return this._layoutHeader.asObservable();
    }

    get layoutNav$() {
        return this._layoutNav.asObservable();
    }

    get parentDrawer$() {
        return this._parentDrawer.asObservable();
    }

    constructor() {
        this.busy$.next(false);
    }

    initializePage(settings: PageLayoutSettings) {
        this._layoutSettings.next(settings);
    }

    navigateParentDrawer(request: ParentDrawerRequest | false) {
        this._parentDrawer.next(request);
    }

    closeParentDrawer() {
        this._parentDrawer.next(false);
    }

    closeParentDrawerWithData(data) {
        this.parentData$.next(data);
    }

    changeNav(nav: FuseNavigationItem) {
        this._layoutNav.next(nav);
    }

    changeHeader(header: ParentHeaderRequest): void {
        this._layoutHeader.next(header);
    }

    getParentDrawerSubject(): Observable<any> {
        return this.parentDrawerSubject.asObservable();
    }

    setParentDrawerSubject(data: any) {
        this.parentDrawerSubject?.next(data);
    }

}
