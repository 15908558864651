import { guestsRoute, authenticatedRoute, landingRoute, customerRoute, officeRoute, driverRoute, notAuthorized } from "./main.routing";

export const tenantsRoutes = [
    // Auth routes for guests
    {
        path: ':tenant-slug',
        ...guestsRoute
    },
    // Auth routes for authenticated users
    {
        path: ':tenant-slug',
        ...authenticatedRoute
    },
    // // Landing routes
    {
        path: ':tenant-slug',
        ...landingRoute
    },
    // // Customer routes
    {
        path: ':tenant-slug',
        ...customerRoute
    },
    // Office routes
    {
        path: ':tenant-slug/office',
        ...officeRoute
    }
    ,
    // Driver routes
    {
        path: ':tenant-slug/driver',
        ...driverRoute
    },
    // Unauthorized routes
    {
        path: ':tenant-slug/not-authorized',
        ...notAuthorized
    }
]