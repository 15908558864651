export enum MediaTypeId {
    Avatar = 1,
    WaypointItem = 2,
    JobItemOption = 3,
    BringgImage = 4,
    WebsiteSettingImage = 5,
    CheckoutSignature = 6,
    WarehouseOrder = 7,
    WarehouseItem = 8,
    WarehouseInstanceThumbnail = 9,
    WarehouseEventSignature = 10,
    WarehouseBillOfLading = 11,
    SidemarkHeader = 12,
    BookingSignature = 13,
    Waypoint = 14,
    WarehouseNote = 15,
    ChatMessage = 16
}