import { environment } from 'environments/environments';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagesService {
  currentMessage = new BehaviorSubject(null)
  private baseUrl = environment.apiUrl;

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    protected httpClient: HttpClient,
    private authService: AuthService
  ) { }

  requestPermission() {
    this.angularFireMessaging.requestPermission.subscribe((permission) => {
      if (permission === 'granted') this.requestToken();
    })
  }
  
  requestToken() {
    this.angularFireMessaging.requestToken.subscribe((token) => {
      if(token && this.authService.accessToken) {
        this.httpClient.post(`${this.baseUrl}api/user-devices`, {userId: this.authService?.userProfile?.userProfile?.userId, deviceId: token}).subscribe();
      }
    })
  }

  requestMessaging() {
    this.angularFireMessaging.messages.subscribe((payload)=> {
      this.currentMessage.next(payload);
    })
  }
}
