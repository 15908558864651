import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import {  AuthorizedRouteBasedOnRole, NotAuthorizedModuleBasedOnRole, OfficeRoles, Role } from 'app/shared/enums/roles.enum';
import { OfficeTenantsService } from 'app/modules/office/tenants/office-tenants.service';
import { guestsRoute } from 'app/main.routing';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard 
{
    public isAppInit: boolean = false; 
    constructor(
        protected _authService: AuthService,
        protected officeTenantsService: OfficeTenantsService,
        protected _router: Router
    )
    {
    }

    public canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this.isAppInit ? this.check(segments) : of(true);
    }

    protected check(segments: UrlSegment[], roles: string[] = null): Observable<boolean | UrlTree>
    {
        const currentPath = `/${segments.join('/')}`;
        const guestPaths = guestsRoute?.children?.map(route => route.path);
        // Check the authentication status
        return this._authService.check().pipe(
            switchMap((authenticated) => {
                const websites =  this.officeTenantsService.getAllTenantsViewItems();
                const websiteSlug = websites?.find(website => {
                    const split = currentPath.split('/');
                    return split.some(item => item.toLowerCase() === website.slug.toLowerCase());
                })?.slug;
                // If the user is not authenticated...
                if ( !authenticated )
                {
                    let url: string;
                    if (guestPaths.some(path => currentPath.includes(path))) {
                        return of(true);
                    }
                    // Check if the URL contains sign-in or sign-up and handle accordingly
                    if (currentPath.includes('/sign-in') || currentPath.includes('/sign-up')) {
                        // Split URL by 'sign-in' or 'sign-up' and dynamically adjust the redirect URL
                        const [baseUrl, query] = currentPath.split(/sign-in|sign-up/);
                        url = `${baseUrl}sign-in?redirectUrl=${query}`;
                    } else {
                        // Redirect to tenant sign-in page
                        if (websiteSlug) {
                            const query = currentPath.split(websiteSlug)[1];
                            url = query ? `${websiteSlug}/sign-in?redirectUrl=${query}` : `${websiteSlug}/sign-in`;
                        } 
                        // Default redirect to sign-in page
                        else {
                            url = `sign-in?redirectUrl=${currentPath}`;
                        }
                    }
                    const urlTree = this._router.parseUrl(url);
                    return of(urlTree);
                }

                const userRoles = this._authService.userProfile.userRoles;
                const hasCustomerRole = userRoles.some(ur => ur.roleName === Role.Customer);
                const hasAdminRole = userRoles.some(ur => ur.roleName === Role.Administrator);
                const hasWarehouseRole = userRoles.some(ur => ur.roleName === Role.Warehouse);
                const isWebsiteMatched = websiteSlug && this._authService.userProfile.userWebsites.some(website => website.slug === websiteSlug);
                const defaultRedirect = hasAdminRole ? 'office/reports' : 'dashboard';

                // Check if the current path matches any of the guest paths
                const isGuestRoute  = guestPaths?.filter(route => route !== 'public' && route !== 'rating').find((path) => {
                    const split = currentPath.split('/');
                    return split.some(item => item.toLowerCase() === path.toLowerCase());
                })
                if (isGuestRoute) {
                    return of(this._router.parseUrl(websiteSlug ? `${websiteSlug}/${defaultRedirect}` : `${defaultRedirect}`));
                }

                if (roles?.length) {
                    const hasRequiredRole = userRoles.some(ur => roles.find(r=>r === ur.roleName));
                    
                    // Check if the user has any restricted module based on their role
                    const isNotAuthorizedModule = userRoles.some(userRole => NotAuthorizedModuleBasedOnRole[userRole.roleName]?.some(path => currentPath.includes(path)));
                    
                    // Check if the user has access to warehouse routes based on their role
                    const isWarehouseAuthorizedRoute = userRoles.some(userRole => AuthorizedRouteBasedOnRole[userRole.roleName]?.some(path => currentPath.endsWith(path)));
                    
                    // Check if the current route is a restricted warehouse route
                    const isWarehouseNotAuthorizedRoute = hasWarehouseRole && currentPath.includes('/office/tenants') && !isWarehouseAuthorizedRoute;

                    if (!hasRequiredRole || isNotAuthorizedModule || isWarehouseNotAuthorizedRoute) {
                        const urlNotAuthorized = this._router.parseUrl(websiteSlug ? `${websiteSlug}/not-authorized`: `not-authorized`);
                        return of(urlNotAuthorized);
                    }
                }
                
                // Redirect to default path if website is matched.
                if (isWebsiteMatched && currentPath.includes('/confirm-membership')) {
                    return of(this._router.parseUrl(websiteSlug ? `${websiteSlug}/${defaultRedirect}` : `${defaultRedirect}`));
                } 
                
                // Redirect to 'confirm-membership' for customers not yet confirmed website.
                else if(websiteSlug && currentPath.includes(websiteSlug) && hasCustomerRole && !isWebsiteMatched && !currentPath.includes('confirm-membership')) {
                    return of(this._router.parseUrl(`${websiteSlug}/confirm-membership`));
                } 
                
                // Redirect to 'not-authorized' for non-admin.
                else if (websiteSlug && !currentPath.includes(websiteSlug) && !hasAdminRole && !hasCustomerRole && !currentPath.includes('not-authorized')) {
                    return of(this._router.parseUrl(`${websiteSlug}/not-authorized`));
                } 
                
                // Redirect to default path if there is no website slug.
                else if (currentPath === '/confirm-membership' && !currentPath.includes(defaultRedirect)) {
                    return of(this._router.parseUrl(websiteSlug ? `${websiteSlug}/${defaultRedirect}` : `${defaultRedirect}`));
                }

                // Allow the access
                return of(true);
            })
        );
    }
}

@Injectable({
    providedIn: 'root'
})
export class DriverAuthGuard extends AuthGuard
{
    authRoles = [Role.Driver];
    protected override check(segments: UrlSegment[]): Observable<boolean | UrlTree>
    {
        return super.check(segments, this.authRoles);
    }
}

@Injectable({
    providedIn: 'root'
})
export class OfficeAuthGuard extends AuthGuard
{
    protected override check(segments: UrlSegment[]): Observable<boolean | UrlTree>
    {
        return super.check(segments, OfficeRoles);
    }
}