<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="bg-gray-200 text-primary print:hidden" [ngClass]="{'mt-0': isScreenSmall}" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.futuristic" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationHeader>
        <!-- Logo -->
        <div class="flex flex-row justify-center bg-bring-blue h-16 mb-4">
            <img class=" w-30 object-contain" [src]="tenantView ? tenantView['mediaFullUrl'] : 'assets/images/logo/full-logo.png'">
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <!-- <ng-container fuseVerticalNavigationFooter>
       
        <div class="flex items-center w-full px-6 py-8  bg-gray-300">
            <user></user>
            <div class="flex flex-col w-full ml-4 ">
                <div class="w-full whitespace-nowrap text-ellipsis leading-normal text-current opacity-80">
                    {{view?.userProfile?.firstName}} {{view?.userProfile?.lastName}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-sm text-ellipsis leading-normal text-current opacity-50">
                    {{view?.user?.email}}
                </div>
            </div>
        </div>
    </ng-container> -->
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <div
        class="sticky top-0 flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b fuse-horizontal-header bg-bring-blue dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button class="mr-2" mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <user class="flex"></user>
            <!-- <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <notifications></notifications>
            <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</div>