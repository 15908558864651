import { HttpClient } from "@angular/common/http";
import { PageRequest } from "app/shared/models/request/page-request.model";
import { PageResponse } from "app/shared/models/response/page-response.model";
import { environment } from "environments/environments";
import { BehaviorSubject, Observable, Subject, map } from "rxjs";
//ignore ts error
// @ts-ignore
import { saveAs } from 'file-saver';
import { Injectable } from "@angular/core";

@Injectable()
export class ReadOnlyCoreApiService<T> {
    protected subject = new BehaviorSubject<PageResponse<T> | null>(null);
    protected subjectRequest = new BehaviorSubject<PageRequest | null>(null);
    protected baseUrl = environment.apiUrl;
    protected endpoint: string;

    constructor(protected httpService: HttpClient, endpoint: string = null) {
        this.endpoint = endpoint;
    }

    get serviceUrl() {
        return `api/${this.endpoint}`;
    }

    get observer$() {
        return this.subject.asObservable();
    }

    get requestObserver$() {
        return this.subjectRequest.asObservable();
    }

    getById(id: number | string): Observable<T> {
        return this.httpService.get<PageResponse<T>>(`${this.baseUrl}api/${this.endpoint}/${id}`)
            .pipe(map((res) => res.item));
    }

    get(request: PageRequest | null): Observable<PageResponse<T>> {
        request = this.getRequest(request);
        return this.httpService.get<PageResponse<T>>(`${this.baseUrl}api/${this.endpoint}`, { params: request.toParams() })
            .pipe(map((res) => {
                res = this.postGet(res);
                res.page.sort = request.sort;
                res.page.sortDir = request.sortDir;
                
                if (!request.noObserverEmit) {
                    this.subject.next(res);
                    this.subjectRequest.next(request);
                }
                return res
            }));
    }

    export(request: PageRequest): void {
        request = this.getRequest(request);
        request.itemsPerPage = 100000;
        request.fileType ??= 'csv';

        this.httpService.get(`${this.baseUrl}api/${this.endpoint}/export`, { observe: 'response', params: request.toParams(), responseType: 'blob' })
            .subscribe((res) => {
                const contentType = res.headers.get('Content-Type');
                const fileName = this.getFilenameFromContentDisposition(res.headers.get('Content-Disposition'));
                const blob = new Blob([res.body], { type: contentType });
                const file = new File([blob], fileName, { type: contentType });
                saveAs(file);
            });
    }

    protected getFilenameFromContentDisposition(contentDisposition: string): string {
        const regex = /filename=(?<filename>[^,;]+);/g;
        const match = regex.exec(contentDisposition);
        const filename = match.groups.filename;
        return filename;
    }

    protected postGet(response: PageResponse<T>): PageResponse<T> {
        return response;
    }

    protected getRequest(request: PageRequest) {
        if (!request || !request.toParams) {
            let finalRequest = new PageRequest();
            Object.keys(request).forEach((key: string) => {
                finalRequest[key] = request[key];
            });
            request = finalRequest;
        }
        return request;
    }
}

