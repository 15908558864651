import { Route } from '@angular/router';
import { authenticatedRoute, customerRoute, driverRoute, guestsRoute, landingRoute, notAuthorized, officeRoute, undefinedRoutes } from './main.routing';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        ...guestsRoute
    },
    // Auth routes for authenticated users
    {
        path: '',
        ...authenticatedRoute
    },
    // Landing routes
    {
        path: '',
        ...landingRoute
    },
    // Customer routes
    {
        path: '',
        ...customerRoute
    },
    // Office routes
    {
        path: 'office',
        ...officeRoute
    },
    // Driver routes
    {
        path: 'driver',
        ...driverRoute
    },
    // Unauthorized routes
    {
        path: 'not-authorized',
       ...notAuthorized
    },
    // Undefined routes
    {
        path: '**',
        ...undefinedRoutes
    }
];
