export enum ErrorCodes {
    UserNotFound = 1001,
    WrongPassword = 1002,
    DuplicatedPhone = 1003,
    SocialUserNotFound = 1004,
    NoPhoneFound = 1005,
    NoUserFound = 1006,
    NoTokenFound = 1007,
    InvalidPhone = 1008,
}
