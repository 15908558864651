//External imports
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ServiceWorkerService {
    constructor(public updates: SwUpdate) {
        if (updates?.isEnabled) {
            //check for update method service worker event is fire every 2 min interval for checking any new version is available or not
            interval(2 * 60 * 1000).subscribe(() =>
                updates?.checkForUpdate().then(() => {})
            );
        }
    }

    /*
     * function is used for checking new deployment version is available or not
     */
    public checkForUpdates(): void {
        this.updates?.versionUpdates?.subscribe((event) => {
            if (event.type === 'VERSION_READY') {
                this.promptUser();
            }
        });
    }

    /*
     * function is used for reload application when new version is available
     */
    public promptUser(): void {
        this.updates?.activateUpdate()?.then(() => document.location.reload());
    }

    /*
     * function is used for clear cache in existing browser applicatin -> storage -> cache storage and it clear our load website only
     * it is not clear local storage data or session storage data
     */
    public clearExistingBrowserCache() {
        caches?.keys()?.then((names) => {
            for (const name of names) {
                caches?.delete(name); //delete existing cache details
            }
        });
    }
}
