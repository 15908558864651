import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CoreApiService } from 'app/shared/services/common/core-api.service';
import { Setting } from 'app/shared/models/domain/domain.model';
import { SettingsWebsiteView, WebsiteSettingsView } from '../models/domain/domain-view.model';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'environments/environments';
import { PageResponse } from '../models/response/page-response.model';

@Injectable({providedIn: 'root'})
export class SettingsService extends CoreApiService<Setting>{
    constructor(httpClient: HttpClient) {
        super(httpClient, 'settings');
    }
}

@Injectable({providedIn: 'root'})
export class WebsiteSettingsViewService extends CoreApiService<WebsiteSettingsView>{
    private subjectWebsiteSettings = new BehaviorSubject<{ [key: string]: WebsiteSettingsView } | null>(null);
    get currentWebsiteSettings$() {
        return this.subjectWebsiteSettings.asObservable();
    }

    constructor(httpClient: HttpClient) {
        super(httpClient, 'website-settings-view');
    }

    getWebsiteSettings(websiteId: number, noObservableEmit?:boolean): Observable<PageResponse<WebsiteSettingsView>> {
        return this.httpService.get<PageResponse<WebsiteSettingsView>>(`${this.baseUrl}api/${this.endpoint}/website/${websiteId}`).pipe(
            map((res) => {
                const obj = res.items.reduce((acc, cur) => {
                    acc[cur.settingSlug] = cur;
                    return acc;
                }, {} as { [key: string]: WebsiteSettingsView });
                if (!noObservableEmit) {
                    this.subjectWebsiteSettings.next(obj)
                };
                return res;
            })
        );
    }

    getPricingWebsiteSettings(websiteId: number = environment.defaultWebsiteId, pricingModel?: number, jobType?: number, driverUserId?: string): Observable<{ [key: string]: WebsiteSettingsView }> {
        let params = new HttpParams();
        if (pricingModel) {
            params = params.set('pricingModel', pricingModel.toString());
        }
        if (jobType) {
            params = params.set('jobType', jobType.toString());
        }
        if (driverUserId) {
            params = params.set('driverUserId', driverUserId);
        }

        return this.httpService.get<PageResponse<WebsiteSettingsView>>(`${this.baseUrl}api/${this.endpoint}/website/${websiteId}/pricing`, {params}).pipe(
            map((res) => {
                const obj = res.items.reduce((acc, cur) => {
                    acc[cur.settingSlug] = cur;
                    return acc;
                }, {} as { [key: string]: WebsiteSettingsView });
                return obj;
            })
        );
    }

}

@Injectable({providedIn: 'root'})
export class SettingsWebsiteViewService extends CoreApiService<SettingsWebsiteView>{
    constructor(httpClient: HttpClient) {
        super(httpClient, 'settings-website-view');
    }
}
