import { Table, liveQuery } from "dexie";
import { DbCoreModel } from "./db-domain.model";
import { from } from "rxjs";
import { generateGuid } from "../functions/utils.type";

export abstract class DbService<T extends DbCoreModel> {
    private readonly maximumDaysToKeep = 7;

    constructor(protected table: Table<T, string>) { 
        this.deleteOlderThan(this.maximumDaysToKeep).then(
            (res) => console.info(`Deleted items older than ${this.maximumDaysToKeep} days from ${this.table.name}: Items deleted ${res}`)
        );
    }

    async getObservable() {
        return from(liveQuery(() => this.table.toArray()));
    }

    async add(item: T): Promise<string> {
        item.id ??= generateGuid();
        item.created = new Date();
        item.modified = item.created;
        return await this.table.add(item);
    }

    async addMany(items: T[]): Promise<string[]> {
        items.forEach(item => {
            item.id ??= generateGuid();
            item.created ??= new Date();
            item.modified = item.created;
        });
        return await this.table.bulkAdd(items, { allKeys: true });
    }

    async update(item: T): Promise<number> {
        item.modified = new Date();
        return await this.table.update(item.id, item);
    }

    async delete(id: string): Promise<void> {
        await this.table.delete(id);
    }

    async get(id: string): Promise<T | undefined> {
        return await this.table.get(id);
    }

    async getAll(): Promise<T[]> {
        return await this.table.toArray();
    }

    async clear(): Promise<void> {
        await this.table.clear();
    }

    async deleteOlderThan(days: number): Promise<number> {
        try {
            const date = new Date();
            date.setDate(date.getDate() - days);
            return await this.table.where('created').below(date).delete();
        }
        catch (error) {
            console.error(error);
            return -1;
        }
    }
}